import { auth } from "./ws-permission.plugin.js";
const PERM_CLASS = "ws-perm-hidden";
// ex <span v-ws-has-permission:subscription.alarms> Only show if has alarms subscription </span>
// ex <span v-ws-has-permission:subscription.alarms.schedule> Only show if has alarms or schedule subscription </span>

export default function (el, binding) {
	let hasP = false;
	Object.keys(binding.modifiers).forEach((k) => {
		hasP |= auth.hasPermissionTo(binding.arg, k);
	});
	if (hasP) {
		el.classList.remove(PERM_CLASS);
	} else {
		el.classList.add(PERM_CLASS);
	}
}
