/**
 * Track "global-banners" height and set it to the bannerHeight ref
 */
import { ref, readonly } from "vue";

const bannerHeight = ref(0);
let resizeObserver = null;

function initTrackBannerHeight() {
	if (!resizeObserver) {
		resizeObserver = new ResizeObserver((entries) => {
			if (entries.length === 0) {
				setBannerHeight(0);
			} else {
				for (const entry of entries) {
					if (entry.target.id === "global-banners") {
						setBannerHeight(entry.contentBoxSize[0].blockSize);
					}
				}
			}
		});
	}

	const timeoutToFindElement = setTimeout(() => {
		if (document.getElementById("global-banners")) {
			resizeObserver.observe(document.getElementById("global-banners"));
			setBannerHeight(
				document.getElementById("global-banners")?.clientHeight ?? 0
			);
			clearTimeout(timeoutToFindElement);
		}
	}, 300);
}

function stopTrackBannerHeight() {
	if (resizeObserver) {
		resizeObserver.disconnect();
	}
}

function setBannerHeight(size) {
	bannerHeight.value = size;
}

export const useBannersHeight = () => {
	return {
		bannerHeight: readonly(bannerHeight),
		initTrackBannerHeight,
		stopTrackBannerHeight
	};
};
