<template>
	<div
		class="navbar-item has-dropdown"
		v-click-outside="closeMenu"
		:class="{ 'is-active': showMenu }"
	>
		<div>
			<ws-button
				is-subtle
				:is-large="!isSmall"
				:is-small="isSmall"
				:is-danger="isDanger"
				:is-warning="isWarning"
				:is-light="isDanger || isWarning"
				:class="{ 'is-selected is-focused': showMenu }"
				@click="toggleMenu"
			>
				<template #icon>
					<slot name="icon" />
				</template>
			</ws-button>
		</div>
		<div class="navbar-dropdown" :class="{ 'is-right': isRight }">
			<slot :close-menu="closeMenu" />
		</div>
	</div>
</template>

<script>
export default {
	name: "MenuDropdown",
	props: {
		isRight: {
			type: Boolean,
			default: true
		},
		isSmall: {
			type: Boolean,
			default: false
		},
		isDanger: {
			type: Boolean,
			default: false
		},
		isWarning: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			showMenu: false
		};
	},

	methods: {
		toggleMenu() {
			this.showMenu = !this.showMenu;
		},

		closeMenu() {
			this.showMenu = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.navbar-item {
	.navbar-dropdown {
		border-radius: 0;
		border-top: 0;
		padding: 1rem;
		min-width: 200px;

		hr.dropdown-divider {
			margin-left: -1rem;
			margin-right: -1rem;
		}

		:slotted(.navbar-item) {
			padding-left: 1rem;
			padding-right: 1rem;
			// background: $white;
			border: 1px solid $color-grey-200;
			// color: $default-text-color;
			margin-bottom: 3px;
			font-weight: bold;
			transition: all 0.1s linear;
			a {
				color: $color-grey-600;
			}
			&:last-child {
				margin-bottom: 0;
			}
			&:hover {
				// background: $color-primary-500 !important;
				// color: $color-on-primary-500 !important;
				border: 1px solid $color-primary-500;
				a {
					color: $color-on-primary-500;
				}
			}
		}
	}
}
</style>
