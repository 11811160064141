<template>
	<div class="tabs">
		<ul>
			<li class="is-active">
				<a>
					{{ $t("results") }}
					<span class="has-text-weight-light ml-10">
						{{ results }}
					</span>
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: "Tabs",

	props: {
		results: {
			type: Number,
			default: 0
		}
	}
};
</script>

<style lang="scss" scoped>
.tabs {
	ul {
		border-bottom-width: 2px;
	}

	li.is-active a {
		border-bottom-color: $color-primary-500;
		font-weight: $weight-bold;
	}

	a:hover {
		border-bottom-color: $color-primary-600;
	}
}
</style>
