export function setTitle(title) {
	document.getElementsByTagName("title")[0].text = title;
}

export const usePageTitle = () => ({
	$setPageTitle: setTitle
});

export default {
	install(Vue) {
		Vue.mixin({
			methods: {
				$setPageTitle(title) {
					setTitle(title);
				}
			}
		});
	}
};
