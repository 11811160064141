import axios from "axios";
import { removeNullParamsFromObject } from "@/helpers/functions.helper.js";

const API = import.meta.env.VITE_API;

export default {
	/**
	 * Get info from logged user or from an specific user
	 * @param {string} email Optional. If not given, get info from logged user
	 *
	 * @return {Promise}
	 */
	async get(email) {
		let url = `${API}/user`;

		if (email) {
			url += "s/" + email;
		}

		return axios
			.get(url)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async getUserWithPrivileges(organizationId) {
		return axios
			.get(`${API}/user?organizationId=${organizationId}`)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	/**
	 * Update user's info
	 * @param  {string} username User's e-mail
	 * @param  {object} infos {fullName: string, phoneNumber: string, language: string}
	 *
	 * @return {Promise}
	 */
	async update({ fullName = null, phoneNumber = null, language = null }) {
		return axios
			.put(`${API}/user`, {
				...removeNullParamsFromObject({
					fullName,
					phoneNumber,
					language
				})
			})
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	/**
	 * Update the user's own password
	 */
	async updatePassword(username, password, passwordConfirm) {
		return axios
			.put(`${API}/user/password`, {
				username,
				password,
				passwordConfirm
			})
			.then((resp) => resp.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async addPrivileges(username, organizationId, privileges) {
		return axios
			.post(`${API}/users/${username}/privileges`, {
				organizationId,
				privileges
			})
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async removePrivilege(username, organizationId, privilegeId) {
		return axios
			.delete(`${API}/users/${username}/privileges`, {
				params: {
					organizationId,
					privilegeId
				}
			})
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async removeAllPrivileges(username, organizationId, genericOnly = false) {
		return axios
			.delete(`${API}/users/${username}/privileges`, {
				params: {
					organizationId,
					genericOnly
				}
			})
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	}
};
