import { auth } from "./ws-permission.plugin.js";
const PERM_CLASS = "ws-perm-hidden";
// ex <span v-ws-has-apps-version-greater="'5.7.1'"> Only show if version >= 5.7.1 </span>
// ex <span v-ws-has-apps-version-greater="'5.6.0'"> Only show if version >= 5.6.0 </span

export default function (el, binding) {
	const hasP = auth.isAppVersionGreater(binding.value);

	if (hasP) {
		el.classList.remove(PERM_CLASS);
	} else {
		el.classList.add(PERM_CLASS);
	}
}
