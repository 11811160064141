/**
 Globally register all base components for convenience, because they will be used very frequently.
 Components are registered using the PascalCased version of their file name.
**/
// import manually global components
export default {
	install(app) {
		// Automatic importer using dynamic import with glob pattern
		const importComponent = import.meta.glob("./ws-*/ws-*.vue", {
			eager: true
		});

		// For each matching file name...
		Object.keys(importComponent).forEach((fileName) => {
			// Get the component config
			const componentConfig = importComponent[fileName];
			// Get the PascalCase version of the component name
			const componentName = fileName
				// Remove the "./folder-name/" from the beginning
				.replace(/^\.\/.*\//, "")
				// Remove the file extension from the end
				.replace(/\.vue$/, "")
				// Split up kebabs and convert to PascalCase
				.split("-")
				.map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
				.join("");

			// Globally register the component
			app.component(
				componentName,
				componentConfig.default || componentConfig
			);
		});
	}
};
