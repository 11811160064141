<template>
	<div>
		<nav class="navbar" role="navigation" aria-label="header navigation">
			<div class="navbar-brand">
				<div class="navbar-burger burger" @click="toggleSidebar()">
					<span />
					<span />
					<span />
				</div>

				<div class="navbar-route-name">
					<span class="view-title">
						{{ formatedTitle }}
					</span>
					<div
						class="view-subtitle is-hidden-touch"
						v-if="!isMobileWidth"
					>
						<div id="customNavbarTitle"></div>
					</div>
				</div>
			</div>
			<div class="navbar-menu">
				<div class="navbar-end is-align-items-center">
					<search />
					<help-resources />
					<user-profile class="ml-1" />
				</div>
			</div>
		</nav>
		<div class="view-subtitle is-hidden-desktop" v-if="isMobileWidth">
			<div id="customNavbarTitle"></div>
		</div>
	</div>
</template>

<script>
import AuthMixin from "@/mixins/auth.mixin.js";
import { EventBus } from "@/eventbus.js";
import Search from "./search.vue";
import UserProfile from "./user-profile.vue";
import HelpResources from "./help-resources.vue";

export default {
	name: "Navbar",

	mixins: [AuthMixin],

	emits: ["isReady", "toggleSidebarMenu"],

	data() {
		return {
			user: {},
			organization: {},
			showNavMenu: false,
			navbarTitle: ""
		};
	},

	created() {
		EventBus.$on("setNavbarTitle", this.setTitle);
	},

	mounted() {
		// when mounted, emit event
		this.$emit("isReady");
	},

	unmounted() {
		EventBus.$off("setNavbarTitle", this.setTitle);
	},

	methods: {
		setTitle(title) {
			this.navbarTitle = title;
		},

		toggleSidebar() {
			EventBus.$emit("toggleSidebarMenu");
		}
	},

	computed: {
		formatedTitle() {
			if (!this.navbarTitle) {
				return "";
			}
			return this.navbarTitle.toString().trim();
		},

		isMobileWidth() {
			return document.body.clientWidth <= 1023;
		}
	},

	components: {
		Search,
		UserProfile,
		HelpResources
	}
};
</script>

<style lang="scss" scoped>
nav.navbar {
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
	padding-left: 1.25rem;
	padding-right: 1.25rem;
	flex-shrink: 0;

	@include mobile {
		& {
			padding-left: 0;
			// padding-right: 0;
		}
		.navbar-burger {
			display: block;
			color: $color-grey-600;
		}

		.navbar-brand {
			flex-shrink: 1;
		}
	}

	.navbar-route-name {
		display: flex;
		flex: 1 1 0%;
		padding: 10px;
		padding-left: 0;
		height: 100%;
		width: 100%;
		align-items: center;

		& > span.view-title {
			font-size: $size-5;
			font-weight: $weight-semibold;
			color: $color-grey-700;

			@include mobile {
				font-size: $size-6;
			}
		}
	}
}

.view-subtitle {
	display: flex;
	background: $white;
}
</style>
