import axios from "axios";
import { $log } from "@/plugins/logger/logger.plugin.js";

const SIZE = 1000;
const requestAll = async (url, params, otherAxiosOptions) => {
	try {
		const data = await axios
			.get(url, {
				params: {
					size: SIZE,
					page: 1,
					...(params || {})
				},
				...(otherAxiosOptions || {})
			})
			.then((res) => res.data);

		// request untill return less data than 'size'
		if (data.length === SIZE) {
			const restOfPages = await requestAll(
				url,
				{
					...(params || {}),
					page: (params?.page || 1) + 1
				},
				otherAxiosOptions
			);
			return data.concat(restOfPages);
		}

		return data;
	} catch (error) {
		$log.debug("Failed getting data", error);
		throw error;
	}
};
export default requestAll;
