/*
API SCHEDULE STRUCTURE:
	{
		disabled,
        scheduleId,
        name,
        description,
        config: {
            defaultStateId: "stateId",
            weekly: [
                [
                    {
                        timeSpan: {
                            start,
                            end
                        },
                        stateId: "state_Id"
                    },
                    ...
                ],
                [],
                [],
                [],
                [],
                [],
                []
            ],
			exceptions: ["exceptionId2","exceptionId1","exceptionId3", ...]
        },
		drivenProperties: [
			{
				propertyId: "prop1",
				applicationPeriodId: "always",
				values: [
					{
						stateId: "state1",
						value: 1
					},
					{
						stateId: "state2",
						value: 9
					}
				]
			},
			{
				propertyId: "prop1",
				applicationPeriodId: "period1",
				values: [
					{
						stateId: "state1",
						value: 2
					},
					{
						stateId: "state2",
						value: 8
					}
				]
			},
			{
				propertyId: "prop1",
				applicationPeriodId: "period2",
				values: [
					{
						stateId: "state1",
						value: 3
					}
				]
			},
			{
				propertyId: "prop2",
				applicationPeriodId: "always",
				values: [
					{
						stateId: "state1",
						value: "A"
					}
				]
			},
			{
				propertyId: "prop2",
				applicationPeriodId: "period1",
				values: [
					{
						stateId: "state2",
						value: "B"
					}
				]
			},
			{
				propertyId: "prop2",
				applicationPeriodId: "period2",
				values: [
					{
						stateId: "state3",
						value: "C"
					}
				]
			}
		],
        createdAt,
        updatedAt,
        tags: {}
    }

	PROP	|  STATE 1 	|  STATE 2 	|  STATE 3 	|  APPLICATION PRIOD
	prop1	|  1		|  9		| 			|  Always
	prop1	|  2		|  8		| 			|  Period 1
	prop1	|  3		|   		| 			|  Period 2
	prop2	|  A		| 			| 			|  Always
	prop2	|   		|  B		| 			|  Period 1
	prop2	|      		| 			| C			|  Period 2

CONSOLE SCHEDULE STRUCTURE:
    {
		enabled,
        scheduleId,
        name,
        description,
        defaultValue: "stateId",
        weekly: [
            [
                {
                    start,
                    end,
                    value: "state_Id"
                },
                ...
            ],
            [],
            [],
            [],
            [],
            [],
            []
        ],
		properties: [
			{
				propertyId: "prop1",
				stateValueMap: {
					state1: 1,
					state2: 9,
				},
				applicationPeriod: "always"
			},
			{
				propertyId: "prop1",
				stateValueMap: {
					state1: 2,
					state2: 8,
				},
				applicationPeriod: "period1"
			},
			{
				propertyId: "prop1",
				stateValueMap: {
					state1: 3
				},
				applicationPeriod: "period2"
			},
			{
				propertyId: "prop2",
				stateValueMap: {
					state1: "A"
				},
				applicationPeriod: "always"
			},
			{
				propertyId: "prop2",
				stateValueMap: {
					state2: "B"
				},
				applicationPeriod: "period1"
			},
			{
				propertyId: "prop2",
				stateValueMap: {
					state3: "C"
				},
				applicationPeriod: "period2"
			}
		],
		exceptions: ["exceptionId2","exceptionId1","exceptionId3", ...],
        createdAt,
        updatedAt,
        tags: {}
    }

API STATE STRUCTURE:
	{
        stateId,
        name,
        color
    }
CONSOLE STATE STRUCTURE:
   {
        stateId,
        name,
        color
    }

API Application Period STRUCTURE:
	{
        applicationPeriodId,
        start: {day, month, year},
        end: {day, month, year}
    }
CONSOLE Application Period STRUCTURE:
   {
        periodId,
        start: "DD/MM/YYYY",
        end: "DD/MM/YYYY", 
		isRecurring: true|false
    }
API Exception STRUCTURE:
	{
        exceptionId,
		name,
		description,
		period: {
        	start: {day, month, year},
        	end: {day, month, year},
		},
		daily: [{
			timeSpan: {
				start,
				end
			},
			stateId: "state_Id"
		}, ...]
    }
CONSOLE Exception STRUCTURE:
   {
        exceptionId,
		name,
		description,
        start: "DD/MM/YYYY",
        end: "DD/MM/YYYY",
		isRecurring: true|false,
		isAllDay: true|false,
		allDayState,
		daily: [
			{
				start,
				end,
				value: "state_Id"
			},
			...
		]
    }
*/

import { isNumber } from "@/helpers/functions.helper.js";
import { useDayjs } from "@/plugins/dayjs/dayjs.plugin.js";

const { $dayjs } = useDayjs();

const DAY_FORMAT = "DD/MM/YYYY";

const isSOD = function (time) {
	return ["00:00", "00:00:00"].includes(time) || !time;
};
const isEOD = function (time) {
	return ["23:59", "24:00", "23:59:00", "24:00:00"].includes(time) || !time;
};

const dailyFromApi = function (day) {
	if (!day) {
		return [];
	}
	return day.map((slot) => {
		return {
			start: slot.timeSpan?.start || null,
			end: slot.timeSpan?.end || "24:00",
			value: slot.stateId
		};
	});
};
const weeklyFromApi = function (weekly) {
	if (!weekly) {
		return [[], [], [], [], [], [], []];
	}
	return weekly.map((day) => {
		return dailyFromApi(day);
	});
};
const dailyToApi = function (day) {
	if (!day) {
		return [];
	}
	return day.map((slot) => {
		return {
			timeSpan: {
				start: slot.start || "",
				end: !isEOD(slot.end) ? slot.end : null
			},
			stateId: slot.value
		};
	});
};
const weeklyToApi = function (weekly) {
	if (!weekly) {
		return [[], [], [], [], [], [], []];
	}
	return weekly.map((day) => {
		return dailyToApi(day);
	});
};
const propertiesFromApi = function (apiProps) {
	return apiProps.map((obj) => {
		return {
			propertyId: obj.propertyId,
			applicationPeriod: obj.applicationPeriodId || "ALL_YEAR",
			stateValueMap: obj.values.reduce((acc, stateValue) => {
				return {
					...acc,
					[stateValue.stateId]: stateValue.value
				};
			}, {})
		};
	});
};
const propertiesToApi = function (consoleProperties) {
	return consoleProperties.map((obj) => {
		return {
			propertyId: obj.propertyId,
			applicationPeriodId:
				!obj.applicationPeriod || obj.applicationPeriod === "ALL_YEAR"
					? null
					: obj.applicationPeriod,
			values: Array.from(Object.keys(obj.stateValueMap), (stateId) => {
				let value = obj.stateValueMap[stateId];
				if (value === "" || value === null) {
					value = null;
				}
				return {
					stateId,
					value: isNumber(value) ? Number(value) : value
				};
			}).filter((val) => val.value !== null)
		};
	});
};
export const scheduleFromApi = function (schedule) {
	const {
		disabled = false,
		scheduleId = null,
		name,
		description = null,
		config,
		drivenProperties,
		createdAt = null,
		updatedAt = null,
		tags = {}
	} = schedule;
	return {
		enabled: !disabled,
		scheduleId,
		name,
		description,
		weekly: weeklyFromApi(config.weekly),
		defaultValue: config.defaultStateId,
		properties: propertiesFromApi(drivenProperties),
		exceptions: config.exceptions || [],
		createdAt,
		updatedAt,
		tags
	};
};
export const scheduleToApi = function (schedule) {
	const {
		enabled = true,
		scheduleId = null,
		name,
		description = null,
		weekly,
		defaultValue,
		properties,
		exceptions = [],
		tags = {}
	} = schedule;
	return {
		disabled: !enabled,
		scheduleId,
		name,
		description,
		config: {
			weekly: weeklyToApi(weekly),
			defaultStateId: defaultValue,
			exceptions
		},
		drivenProperties: propertiesToApi(properties),
		tags
	};
};

export const stateFromApi = function (state) {
	const { stateId = null, name, color } = state;
	return {
		stateId,
		name,
		color
	};
};
export const stateToApi = function (state) {
	const { stateId = null, name, color } = state;
	return {
		stateId,
		name,
		color
	};
};

const dateToObject = function (date, isRecurring) {
	const obj = {
		day: $dayjs(date, DAY_FORMAT).format("DD"),
		month: $dayjs(date, DAY_FORMAT).format("MM")
	};
	if (!isRecurring) {
		obj.year = $dayjs(date, DAY_FORMAT).format("YYYY");
	}

	return obj;
};

export const periodFromApi = function (period) {
	const { applicationPeriodId = null, start, end } = period;
	let { day: dayS, month: monthS, year: yearS } = start;
	let { day: dayE, month: monthE, year: yearE } = end;

	const currentYear = $dayjs(new Date()).format("YYYY");

	const isRecurring = !yearS && !yearE;
	if (isRecurring) {
		yearS = currentYear;

		yearE = monthS <= monthE ? currentYear : Number(currentYear) + 1;
	}
	return {
		periodId: applicationPeriodId,
		start: $dayjs(dayS + "/" + monthS + "/" + yearS, "D/M/YYYY").format(
			DAY_FORMAT
		),
		end: $dayjs(dayE + "/" + monthE + "/" + yearE, "D/M/YYYY").format(
			DAY_FORMAT
		),
		isRecurring
	};
};
export const periodToApi = function (period) {
	const { periodId = null, start, end, isRecurring } = period;
	return {
		applicationPeriodId: periodId,
		start: dateToObject(start, isRecurring),
		end: dateToObject(end, isRecurring)
	};
};

const isSlotAllDay = function (apiSlot) {
	return isSOD(apiSlot?.timeSpan?.start) && isEOD(apiSlot?.timeSpan?.end);
};

export const exceptionFromApi = function (exception) {
	const {
		exceptionId = null,
		name,
		description,
		period = {},
		daily = []
	} = exception;
	const { start = {}, end = {} } = period;

	let { day: dayS, month: monthS, year: yearS } = start;
	let { day: dayE, month: monthE, year: yearE } = end;

	const currentYear = $dayjs(new Date()).format("YYYY");

	const isRecurring = !yearS && !yearE;
	if (isRecurring) {
		yearS = currentYear;

		yearE = monthS <= monthE ? currentYear : Number(currentYear) + 1;
	}

	const isAllDay = daily.length === 1 && isSlotAllDay(daily[0]);
	const consoleDaily = dailyFromApi(daily);
	return {
		exceptionId,
		name,
		description,
		start: $dayjs(dayS + "/" + monthS + "/" + yearS, "D/M/YYYY").format(
			DAY_FORMAT
		),
		end: $dayjs(dayE + "/" + monthE + "/" + yearE, "D/M/YYYY").format(
			DAY_FORMAT
		),
		isRecurring,
		isAllDay,
		allDayState: isAllDay ? consoleDaily[0]?.value : null,
		daily: consoleDaily
	};
};
export const exceptionToApi = function (exception) {
	const {
		exceptionId = null,
		name,
		description,
		start,
		end,
		isRecurring,
		isAllDay = false,
		allDayState = null,
		daily
	} = exception;
	return {
		exceptionId,
		name,
		description,
		period: {
			start: dateToObject(start, isRecurring),
			end: dateToObject(end, isRecurring)
		},
		daily: isAllDay
			? [
					{
						timeSpan: {
							start: "00:00",
							end: null
						},
						stateId: allDayState
					}
				]
			: dailyToApi(daily)
	};
};
