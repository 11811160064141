/**
 *
 * @param {Object} device Device from API
 * @param {Object} opts { newActivateBox: boolean }
 * @returns string
 */
export const landingRoute = (device, opts) => {
	const { newActivatedBox = false } = opts || {};

	if (!device) {
		return "box-v1-settings";
	}

	// if device is a cloud-hub to redirect to hub view
	if (device.type === "BRIDGE" || device.type === "CLASSICAL_HUB") {
		// should add permissions check, but at this level it would require a lot of calls & computation
		return "hub-v1-settings";
	}

	// if it's a brand new activated box, redirect to configuration
	if (newActivatedBox) {
		return "box-v1-settings";
	}

	// if any unresolved alarm, redirect user to alarms list
	if (device?.hasAlarm) {
		return "box-v1-alarms-list-history";
	}

	return "box-v1-dashboard"; // default route
};
