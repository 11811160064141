export default [
	{
		path: "/user-profile",
		name: "user-profile-view",
		meta: {
			requiresAuth: true
		},
		redirect: {
			name: "user-profile"
		},
		component: () => import("./user-profile.vue"),
		children: [
			{
				path: "profile",
				name: "user-profile",
				component: () => import("./views/profile.vue")
			},
			{
				path: "security",
				name: "user-security",
				component: () => import("./views/security.vue")
			}
		]
	}
];
