import axios from "axios";
const API = import.meta.env.VITE_API;

export default {
	deviceId: null,

	setDeviceId(deviceId) {
		this.deviceId = deviceId;
	},

	async getTasks(deviceId = null) {
		return axios
			.get(`${API}/devices/${deviceId || this.deviceId}/tasks`)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async queueTask(task, deviceId = null) {
		return axios
			.post(
				`${API}/devices/${deviceId || this.deviceId}/tasks/queue`,
				task
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async queueRVLMigrationTask(equipmentId, deviceId = null) {
		return axios
			.post(`${API}/devices/${deviceId || this.deviceId}/tasks/queue`, {
				type: "RVL_MIGRATION",
				equipmentId
			})
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	}
};
