<template>
	<ws-icon v-bind="props" />
</template>

<script setup>
import { onMounted } from "vue";
import { useLogMonitoring } from "@/plugins/user-console-monitoring/useLogMonitoring.js";

const { notify: $logNotifier } = useLogMonitoring();

onMounted(() => {
	$logNotifier(
		"[font-awesome-icon] font-awesome-icon is deprecated but it's being called. Use ws-icon instead."
	);
});

const props = defineProps({
	icon: {
		type: String,
		default: null,
		required: true
	},
	size: {
		type: String,
		default: "1x",
		validator: function (value) {
			return [
				"xs",
				"sm",
				"md",
				"lg",
				"1x",
				"2x",
				"3x",
				"4x",
				"5x",
				"6x",
				"7x",
				"8x",
				"9x",
				"10x"
			].includes(value);
		}
	},
	rotate: {
		type: String,
		default: "0",
		validator: function (value) {
			if (!value) {
				return true;
			}
			return ["0", "45", "-45", "90", "-90", "180", "270"].includes(
				value.toString()
			);
		}
	},
	flipHorizontal: {
		type: Boolean,
		default: false
	},
	flipVertical: {
		type: Boolean,
		default: false
	},
	spin: {
		type: Boolean,
		default: false
	}
});
</script>
