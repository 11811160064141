const DisclamerView = () => import("./disclaimer.vue");
const EventsCodeListView = () => import("./events-code.vue");

export default [
	{
		name: "disclosure",
		path: "/open-source-software-disclosure",
		meta: {
			layout: "plain-html",
			requiresAuth: false
		},
		component: DisclamerView
	},
	{
		name: "list-events-code",
		path: "/list-events-code",
		meta: {
			layout: "plain-html",
			requiresAuth: false
		},
		component: EventsCodeListView
	}
];
