<template>
	<router-link
		v-bind="$attrs"
		class="sidebar-item"
		:class="{
			'router-link-active': props.isActive
		}"
		:aria-label="sidebarText"
		v-tooltip="{
			disabled: props.isExpanded,
			content: sidebarText
		}"
	>
		<ws-icon
			:icon="props.icon || props.faIcon"
			class="sidebar-icon"
			size="lg"
		/>
		<span v-if="props.isExpanded">
			<slot />
		</span>
	</router-link>
</template>

<script setup>
import { computed, useSlots } from "vue";

const props = defineProps({
	icon: {
		type: String,
		default: null,
		required: false
	},
	faIcon: {
		type: String,
		default: null,
		required: false
	},
	isExpanded: {
		type: Boolean,
		default: true
	},
	isActive: {
		type: Boolean,
		default: false
	}
});

const slots = useSlots();

const sidebarText = computed(() => {
	return slots.default()[0]?.children?.toString().trim() || "-";
});
</script>

<style lang="scss" scoped>
$color-sidebar-item-hover-background: $color-grey-300;

.sidebar-item {
	display: flex;
	align-items: flex-start;
	padding: 0.75rem;
	line-height: 1.5rem;
	border-radius: 0.375rem;
	color: $color-grey-700;
	font-size: $size-6;
	font-weight: $weight-medium;

	&:focus-visible {
		outline: 2px auto $focus-outline-color;
		outline-offset: 0;
	}

	&.router-link-active {
		color: $color-primary-500;
	}

	&:hover {
		background-color: $color-sidebar-item-hover-background;
	}

	& > span {
		margin-left: 0.5rem;
	}
	.sidebar-icon {
		width: 28px;
	}
}
</style>
