<template>
	<div v-if="showUnpairedDevices && hasUnpairedDevices">
		<ws-notification-message
			type="info"
			class="m-0 p-3"
			:show-close-button="true"
			is-full-screen
		>
			<i18n-t
				keypath="banner.unpaired-devices.body"
				tag="span"
				scope="global"
			>
				<template #goToLink>
					<router-link :to="{ name: 'organization-devices' }">
						{{ $t("sidebar-menu-item.company") }} >
						{{
							isSiemens
								? $t("licenses")
								: $t("tabs.organization.devices")
						}}
					</router-link>
				</template>
			</i18n-t>
		</ws-notification-message>
	</div>
</template>
<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import AuthService from "@/services/Auth.service.js";
import SiemensRatePlanService from "@/services/v1/SiemensRatePlan.service.js";
import { useLogger } from "@/plugins/logger/logger.plugin.js";
import { useClientName } from "@/plugins/client-name/client-name.plugin.js";

const { clientName } = useClientName();
const isSiemens = computed(() => clientName === "siemens");
const { error: $logError } = useLogger();
const route = useRoute();

const hasUnpairedDevices = ref(false);
const showUnpairedDevices = computed(() => route.name === "boxes-map");
let user = null;
onMounted(async () => {
	user = await AuthService.getUserInfo();
	await checkIfThereAreUnpairedDevices();
});
async function checkIfThereAreUnpairedDevices() {
	try {
		const ratePlans = await SiemensRatePlanService.list({
			ownershipType: "BUYER"
		});
		const organizationIdsUserIsAdmin = Object.entries(
			user?.privileges || {}
		).reduce((acc, [organizationId, privileges]) => {
			const isAdmin = privileges
				.flatMap((privilege) => privilege.action)
				.includes("Admin");
			if (!isAdmin) {
				return acc;
			}
			return [...acc, organizationId];
		}, []);
		hasUnpairedDevices.value =
			ratePlans?.filter(
				(ratePlan) =>
					ratePlan?.status?.lifecycle === "AVAILABLE" &&
					organizationIdsUserIsAdmin.includes(
						ratePlan?.buyerOrganizationId
					)
			).length > 0;
	} catch (error) {
		$logError("failed getting paired ratePlans", error);
		hasUnpairedDevices.value = false;
	}
}
</script>