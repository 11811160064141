<template>
	<menu-dropdown>
		<template #icon>
			<ws-icon icon="user" size="lg" />
		</template>

		<template #default="{ closeMenu }">
			<div class="user-infos">
				<div class="has-text-black has-text-weight-bold is-size-6">
					<span>{{ user.fullName || user.username }}</span>
				</div>
			</div>

			<hr class="dropdown-divider" />

			<ws-language-selector
				@update:model-value="changedLanguage"
				v-model="user.language"
			/>

			<hr class="dropdown-divider" />

			<router-link
				class="navbar-item"
				:to="{ name: 'user-profile' }"
				@click="closeMenu"
			>
				{{ $t("my-profile") }}
			</router-link>

			<a class="navbar-item" @click="logout">
				{{ $t("logout") }}
			</a>
		</template>
	</menu-dropdown>
</template>

<script>
import MenuDropdown from "./components/menu-dropdown.vue";
import EventBus from "@/eventbus.js";
import AuthService from "@/services/Auth.service.js";
import UsersService from "@/services/v1/Users.service.js";
import AuthMixin from "@/mixins/auth.mixin.js";

export default {
	name: "UserProfile",

	mixins: [AuthMixin],

	data() {
		return {
			user: {}
		};
	},

	created() {
		EventBus.$on("changedfullName", (newfullName) => {
			this.user.fullName = newfullName;
		});
		EventBus.$on("changedLanguage", (newLanguage) => {
			this.user.language = newLanguage;
		});
	},

	mounted() {
		this.getUser();
	},

	methods: {
		setGTMVariables() {
			if (this.$gtm?.enabled()) {
				window.dataLayer.push({
					user_email: this.user.username,
					// user_first_activation_date: "",
					white_label: this.$clientName !== "wattsense",
					wattsense_staff: this.$_isWattsense // or this.user.isWattsense,
				});
			}
		},
		async getUser() {
			try {
				this.user = await UsersService.get();
				if (this.user.language == null) {
					this.user.language = "en";
				}
				this.$i18n.locale = this.user.language;
				EventBus.$emit("changedLanguage", this.$i18n.locale);
				this.$dayjsSetLocale(this.$i18n.locale);
				this.setGTMVariables();
			} catch (err) {
				this.$log.error("user-profile.vue", err);
				this.$toasted.error(this.$t("failed-getting-your-profile"));
			}
		},

		async changedLanguage(newLocale) {
			this.$i18n.locale = newLocale;
			EventBus.$emit("changedLanguage", newLocale);
			this.$dayjsSetLocale(newLocale);
			await this.saveNewUserLocale(newLocale);
		},

		async logout() {
			this.$clearSession();
			await AuthService.logout();
			this.$router.go(0);
		},

		async saveNewUserLocale(newLocale) {
			if (this.$_isDemo) {
				return;
			}

			try {
				const locale = {
					language: newLocale
				};
				AuthService.updateUser(locale);
				await UsersService.update(locale);
			} catch (err) {
				this.$toasted.error("Failed updating.");
			}
		}
	},

	components: {
		MenuDropdown
	}
};
</script>

<style lang="scss" scoped>
.user-infos {
	& > div {
		word-break: break-all;
	}
}
</style>
