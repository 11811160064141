import axios from "axios";

const API = import.meta.env.VITE_API;
const LOGOUT = import.meta.env.VITE_API_LOGOUT;
const API_SUPPORT = import.meta.env.VITE_API_SUPPORT;
const AUTH_TOKEN = import.meta.env.VITE_AUTH_TOKEN;
const USER = import.meta.env.VITE_USER;

export default {
	/**
	 * Check if is a demo user who is logged in
	 *
	 * @return {Boolean}
	 */
	isDemo() {
		const loggedUser = this.user();
		return loggedUser
			? loggedUser.username.toLowerCase() === "demo@wattsense.com"
			: false;
	},

	/**
	 * Check if the logged in user is a Wattsense user
	 *
	 * @return {Boolean}
	 */
	isWattsense() {
		const loggedUser = this.user();
		return (
			!this.isDemo() &&
			loggedUser?.username?.toLowerCase().endsWith("@wattsense.com")
		);
	},

	/**
	 * Check if logged user is account admin
	 *
	 * @return {Boolean}
	 */
	isAccountAdmin() {
		const loggedUser = this.user();
		return loggedUser ? loggedUser.isOrganizationAdmin : false;
	},

	/**
	 * Check if user is read only on all his organizations
	 *
	 * @return {Boolean}
	 */
	isReadOnlyUser() {
		const loggedUser = this.user();
		if (!loggedUser) {
			return true;
		}

		for (const organizationId of Object.keys(loggedUser?.privileges)) {
			for (const privilege of loggedUser.privileges[organizationId]) {
				const permission = privilege.action;
				if (
					permission.includes("Write") ||
					["*", "Admin", "admin"].includes(permission)
				) {
					return false;
				}
			}
		}

		return true;
	},

	/**
	 * Verify if user is authenticated
	 *
	 * @return {Boolean}
	 */
	isAuthenticated() {
		return !!localStorage.getItem(AUTH_TOKEN);
	},

	/**
	 * Login user in API
	 *
	 * @param  {string} username
	 * @param  {string} password
	 * @return {Promise}
	 */
	async login(username, password) {
		return axios
			.get(`${API}/user`, {
				auth: {
					username: username,
					password: password
				}
			})
			.then((resp) => {
				const token = resp.headers[AUTH_TOKEN];
				localStorage.setItem(AUTH_TOKEN, token);

				const data = resp.data;
				localStorage.setItem(USER, JSON.stringify(data));

				return {
					token: token,
					user: data
				};
			})
			.catch((err) => {
				this.clearSession();
				throw err.response;
			});
	},

	/**
	 * Logout user
	 */
	async logout() {
		if (localStorage.getItem(AUTH_TOKEN)) {
			await axios.get(LOGOUT).catch(() => {});
		}
		this.clearSession();
	},

	/**
	 * Removes Tokens
	 */
	clearSession() {
		localStorage.removeItem(AUTH_TOKEN);
		localStorage.removeItem(USER);
		localStorage.removeItem("last-selected-organizationId");
		localStorage.removeItem("HIDE_EXPIRING_BOX_NOTIFICATIONS");
	},

	/**
	 * Request password reset link
	 *
	 * @param  {string} email User's email address
	 * @return {Promise}
	 */
	async requestNewPassword(email) {
		return axios
			.get(`${API_SUPPORT}/password-reset`, {
				params: {
					username: email
				}
			})
			.then((resp) => resp.data)
			.catch((err) => {
				throw err.response;
			});
	},

	/**
	 * Reset password
	 *
	 * @param  {string} token Token provided
	 * @param  {string} email User's email address
	 * @param  {string} password New password
	 * @param  {string} password_confirmation New password confirmation
	 * @return {Promise}
	 */
	async resetPassword(token, email, password, password_confirmation) {
		return axios
			.put(`${API_SUPPORT}/new-password?token=${token}`, {
				username: email,
				password: password,
				passwordConfirm: password_confirmation
			})
			.then((resp) => resp.data)
			.catch((err) => {
				throw err.response;
			});
	},

	/**
	 * Get info from logged user
	 * @return {Promise}
	 */
	async getUserInfo() {
		return axios
			.get(`${API}/user`)
			.then((resp) => {
				const user = resp.data;
				localStorage.setItem(USER, JSON.stringify(user));
				return user;
			})
			.catch((err) => {
				localStorage.removeItem(USER);
				throw err.response;
			});
	},

	/**
	 * Logged user infos
	 *
	 * @return {json}
	 */
	user() {
		try {
			return JSON.parse(localStorage.getItem(USER));
		} catch (error) {
			return {};
		}
	},

	updateUser(user) {
		const updateUser = {
			...this.user(),
			...user
		};
		localStorage.setItem(USER, JSON.stringify(updateUser));
	}
};
