export default [
	{
		path: "/organization",
		name: "organization-view",
		meta: {
			requiresAuth: true
		},
		redirect: {
			name: "organization"
		},
		component: () => import("./organization.vue"),
		children: [
			{
				path: "organization",
				name: "organization",
				component: () => import("./views/organization.vue")
			},
			{
				path: "user-management",
				name: "user-management",
				component: () => import("./views/user-management.vue")
			},
			{
				path: "service-accounts",
				name: "organization-service-accounts",
				component: () => import("./views/service-accounts.vue")
			},
			{
				path: "devices",
				alias: "rate-plans",
				name: "organization-devices",
				component: () => import("./views/devices/devices.vue")
			},
			{
				path: "data-streams",
				name: "organization-data-streams",
				component: () => import("./views/data-streams/data-streams.vue")
			}
		]
	}
];
