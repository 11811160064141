import axios from "axios";
const API = import.meta.env.VITE_API;

/**
 * Idea of this plugin is to, every {sessionTime} minutes, it will call API to keep auth-token active in the BE
 * this way, even if user is not using console for a long time, but tab is opened, it will keep user's session
 */

let sessionTime = null; // frequency in which it will refresh user's token (in minutes)
let sessionIntervalId = null; // id of setInterval to keep refreshing user's token

/**
 * Set interval to keep refreshing user's token every {sessionTimeInMinutes} minutes
 * @param {int} sessionTimeInMinutes Frequency it should refresh user's token (in minutes)
 */
function createInterval(sessionTimeInMinutes) {
	sessionIntervalId = setInterval(async () => {
		try {
			await axios.get(`${API}/user`, {
				params: {
					showSpinner: false
				}
			});
		} catch {
			clearSession(sessionIntervalId);
		}
	}, sessionTimeInMinutes * 60000);
}

/**
 * Init session for user
 * @param {int} sessionTimeInMinutes Frequency it should refresh user's token (in minutes)
 */
function initKeepSession(sessionTimeInMinutes) {
	if (sessionIntervalId) {
		clearSession(sessionIntervalId);
	}
	createInterval(sessionTimeInMinutes || sessionTime);
}

/**
 * Stop refreshing token
 * @param {int} sessionId setInterval Id
 */
function clearSession(sessionId) {
	const sId = sessionId || sessionIntervalId;
	clearInterval(sId);
}

export const useSessionKeeper = () => ({
	initKeepSession,
	clearSession
});

export default {
	install(
		app,
		options = {
			sessionTimeInMinutes: 29
		}
	) {
		sessionTime = options.sessionTimeInMinutes;
		app.$initKeepSession = initKeepSession;
		app.$clearSession = clearSession;
		app.config.globalProperties.$initKeepSession = app.$initKeepSession;
		app.config.globalProperties.$clearSession = app.$clearSession;
	}
};
