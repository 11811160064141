export default [
	{
		path: "/hubs-list",
		name: "hubs",
		meta: {
			requiresAuth: true
		},
		component: () => import("./hub-list.vue")
	}
];
