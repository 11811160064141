import { createRouter, createWebHistory } from "vue-router";
import AuthService from "@/services/Auth.service.js";

import AuthRoutes from "@/views/auth/routes.js";
import DisclaimerRoutes from "@/views/disclaimer/routes.js";
import BoxListRoutes from "@/views/box-list/routes.js";
import BoxListMapRoutes from "@/views/box-list-map/routes.js";
import HubsListRoutes from "@/views/hub-list/routes.js";
import AlarmsRoutes from "@/views/alarms/routes.js";
import BoxRoutes from "@/views/box/routes.js";
import HubRoutes from "@/views/hub/routes.js";
import UserProfileRoutes from "@/views/user-profile/routes.js";
import OrganizationRoutes from "@/views/organization/routes.js";
import FileResourcesManagementRoutes from "@/views/file-resources-management/routes.js";

import { useLogMonitoring } from "@/plugins/user-console-monitoring/useLogMonitoring.js";

function setMonitoringUser(user) {
	try {
		useLogMonitoring().setUser({
			name: user.fullName,
			email: user.username
		});
	} catch (error) {
		useLogMonitoring().notify("failed setting user on datadog", error);
	}
}

const defaultPath = "boxes-map";
const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			name: "home",
			path: "/",
			redirect: { name: defaultPath },
			beforeEnter: async (to, from, next) => {
				try {
					await AuthService.getUserInfo();
					return next();
				} catch (error) {
					return next("/login");
				}
			}
		},
		...DisclaimerRoutes,
		...AuthRoutes,
		...HubsListRoutes,
		...BoxListRoutes,
		...BoxListMapRoutes,
		...AlarmsRoutes,
		...BoxRoutes,
		...HubRoutes,
		...UserProfileRoutes,
		...OrganizationRoutes,
		...FileResourcesManagementRoutes
	]
});

// Before each route, check if user is logged in
// If not, redirect to /login
router.beforeEach(async (to, from, next) => {
	/**
	 * When navigation from a box-v1 OR hub-v1 route to a box-v1 OR hub-v1 route, inspect if user is changing from BOX to HUB or HUB to BOX. If it's the case, then redirect user to TO but with the prefix from FROM. Example:
	 * FROM hub-v1-settings TO box-v1-settings-equipment -> TO hub-v1-settings-equipment
	 *
	 * This is necessary because box and hub routes share the same components AND most of the components are redirecting user to box-v1-.... But if user is in a HUB, he should be redirected to hub-v1-... and not box-v1-.... So, to avoid changing a lot of links, this function detects if user is moving around box/hub to keep user in the same route
	 */
	function changeToBoxHubRouteIfNeeded(to, from, next) {
		const fromName = from?.name?.slice(0, 6);
		const toName = to?.name?.slice(0, 6);
		const prefixRoutes = ["box-v1", "hub-v1"];
		if (prefixRoutes.includes(fromName) && prefixRoutes.includes(toName)) {
			// check if it's changing the deviceId (means that it's changing box). If it is, simply call next() to do not change the route
			// if it's the same deviceid, then change route to keep the from-prefix
			const toDeviceId = to?.params?.id;
			const fromDeviceId = from?.params?.id;
			if (fromName !== toName && toDeviceId === fromDeviceId) {
				return next({
					name: fromName + to.name.slice(6),
					params: to.params,
					query: to.query
				});
			} else {
				return next();
			}
		}

		return next();
	}

	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!AuthService.isAuthenticated()) {
			return next({
				name: "login",
				query: {
					redirect: to.fullPath
				}
			});
		}

		setMonitoringUser(AuthService.user());
	}

	document.dispatchEvent(new Event("changedroute"));
	return changeToBoxHubRouteIfNeeded(to, from, next);
});

export default router;
