import vClickOutside from "click-outside-vue3";
import { vTooltip, Tooltip } from "floating-vue";
import { dragscroll } from "vue-dragscroll";

export default {
	install(app) {
		app.directive("ClickOutside", vClickOutside.directive);
		app.directive("Tooltip", vTooltip);
		app.directive("dragscroll", dragscroll);
		app.component("VTooltip", Tooltip);

		// Automatic importer using dynamic import with glob pattern
		const importDirective = import.meta.glob("./ws-*/ws-*.directive.js", {
			eager: true
		});

		// For each matching file name...
		Object.keys(importDirective).forEach((fileName) => {
			// Get the component config
			const directiveConfig = importDirective[fileName];
			// Get the PascalCase version of the component name
			const directiveName = fileName
				// Remove the "./folder-name/" from the beginning
				.replace(/^\.\/.*\//, "")
				// Remove the file extension from the end
				.replace(/\.directive\.js$/, "")
				// Split up kebabs and convert to PascalCase
				.split("-")
				.map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
				.join("");

			// Globally register the directive
			app.directive(
				directiveName,
				directiveConfig.default || directiveConfig
			);
		});
	}
};
