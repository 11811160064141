import { normalizeString, naturalSorting } from "@/helpers/functions.helper.js";
import stringSimilarity from "string-similarity";

export function createEquipmentKeywordsDictionary(equipment) {
	const lorawanKeywords = prepareLorawanKeywords(equipment);
	const protocolArray = prepareArrayFromProtocolNameToSearch(
		equipment.config.protocol
	);
	const nameArray = prepareTermToSearch(equipment.name)
		.replace("-", "")
		.split(" ")
		.filter((name) => !!name);
	const protocolNameArray = prepareArrayWithProtocolAndEquipmentName(
		protocolArray,
		nameArray
	);
	return [
		...protocolArray,
		...nameArray,
		nameArray.join(" "),
		...protocolNameArray,
		...lorawanKeywords
	].filter((keyword) => [null, "", " "].indexOf(keyword) === -1);
}

export function filterEquipmentByTermAndDictionary(
	equipmentList,
	term,
	dictionary
) {
	if (!term) {
		return {
			type: "no-term",
			list: equipmentList.sort((equipmentA, equipmentB) =>
				sortByKey(equipmentA, equipmentB, "deviceId")
			)
		};
	}

	const simpleFilter = simpleFilterEquipment(equipmentList, term);
	if (simpleFilter.length > 0) {
		return {
			type: "exact",
			list: simpleFilter.sort((equipmentA, equipmentB) =>
				sortByKey(equipmentA, equipmentB, "deviceId")
			)
		};
	}

	const filteredEquipmentIds = filterEquipmentIdsByDictionary(
		dictionary,
		term
	);
	return {
		type: "approximate",
		list: equipmentList.filter((equipment) =>
			filteredEquipmentIds.includes(equipment.equipmentId)
		)
	};
}

export function filterBoxesByTerm(boxList, term) {
	if (term) {
		term = normalizeString(term);
		boxList = boxList.filter((device) => {
			const name = prepareTermToSearch(device.name);
			const deviceId = prepareTermToSearch(device.deviceId);
			const hardwareId = prepareTermToSearch(device.hardwareId);

			const searchByName = name ? name.indexOf(term) !== -1 : false;
			const searchById = deviceId ? deviceId.indexOf(term) !== -1 : false;
			const searchByHardwareId = hardwareId
				? hardwareId.indexOf(term) !== -1
				: false;

			return searchByName || searchById || searchByHardwareId;
		});
	}
	// order by _status online;offline;others
	return boxList
		.sort((boxA, boxB) => naturalSorting(boxA.name, boxB.name))
		.sort((boxA, boxB) => sortByKey(boxA, boxB, "_status"));
}

export function prepareTermToSearch(term) {
	if (term) {
		return normalizeString(term.toString().toLowerCase().trim());
	}

	return false;
}

const simpleFilterEquipment = (equipmentList, term) => {
	if (term) {
		term = normalizeString(term);
	}
	return equipmentList.filter((equipment) => {
		const name = prepareTermToSearch(equipment.name);
		const protocol = prepareTermToSearch(equipment.config.protocol);

		const searchByName = name ? name.indexOf(term) !== -1 : false;
		const searchByProtocol = protocol
			? protocol.indexOf(term) !== -1
			: false;

		let extraFilter = false;
		if (equipment.config.protocol === "LORAWAN_V1_0") {
			const devEUI = prepareTermToSearch(equipment.config.devEUI);
			const appEUI = prepareTermToSearch(equipment.config.appEUI);
			const searchByDevEUI = devEUI ? devEUI.indexOf(term) !== -1 : false;
			const searchByAppEUI = appEUI ? appEUI.indexOf(term) !== -1 : false;
			extraFilter = searchByDevEUI || searchByAppEUI;
		}

		return searchByName || searchByProtocol || extraFilter;
	});
};

const filterEquipmentIdsByDictionary = (equipmentDictionary, term) => {
	if (term) {
		term = normalizeString(term);
	}
	return (
		equipmentDictionary
			.map((equipment) => {
				const similarity = stringSimilarity.findBestMatch(
					term,
					equipment.keywords
				);
				return {
					...equipment,
					similarity: similarity.bestMatch.rating
				};
			})
			.filter((equipment) => equipment.similarity > 0.9)
			// .sort((equipmentA, equipmentB) => sortByKey(equipmentA, equipmentB, "similarity"))
			.map((equipment) => equipment.equipmentId)
	);
};

const prepareLorawanKeywords = (equipment) => {
	return equipment.config.protocol !== "LORAWAN_V1_0"
		? []
		: [
				equipment.config.devEUI.toString().toLowerCase(),
				equipment.config.appEUI.toString().toLowerCase()
		  ];
};

const prepareArrayFromProtocolNameToSearch = (protocol) => {
	const arrayProtocol = protocol.toLowerCase().split("_");
	if (arrayProtocol[0] && arrayProtocol[0] === "lorawan") {
		return [arrayProtocol[0]];
	} else {
		return [arrayProtocol[0], arrayProtocol.join(" ")];
	}
};

const prepareArrayWithProtocolAndEquipmentName = (protocolArray, nameArray) => {
	const concatProtocolName = [];
	for (
		let indexProtocol = 0;
		indexProtocol < protocolArray.length;
		indexProtocol++
	) {
		const protocol = protocolArray.slice(0, indexProtocol + 1).join(" ");
		for (let indexName = 0; indexName < nameArray.length; indexName++) {
			concatProtocolName.push(
				normalizeString(`${protocol} ${nameArray[indexName]}`)
			);
			if (indexName >= 1) {
				const name = nameArray.slice(0, indexName + 1).join(" ");
				concatProtocolName.push(normalizeString(`${protocol} ${name}`));
			}
		}
	}

	return concatProtocolName;
};

const sortByKey = (objA, objB, keyToCompare) => {
	if (objA[keyToCompare] == objB[keyToCompare]) {
		return 0;
	}
	if (objA[keyToCompare] > objB[keyToCompare]) {
		return 1;
	}
	return -1;
};
