// https://vuejs.org/v2/guide/custom-directive.html#Intro
// Register a global custom directive called `v-ws-scroll`
export default {
	mounted: (el, binding) => {
		let f = function (evt) {
			if (binding.value(evt, el)) {
				window.removeEventListener("scroll", f);
			}
		};
		window.addEventListener("scroll", f);
	}
};
