import axios from "axios";

const MAPBOXAPI = "https://api.mapbox.com/geocoding/v5/mapbox.places";
const MAPBOXKEY = import.meta.env.VITE_MAPBOX_KEY;

export default {
	async getLocation({
		term, // term : search or coordinates
		autocomplete = true,
		types = "country,region,postcode,district,place,locality,neighborhood,address,poi", // types : country,region,postcode,district,place,locality,neighborhood,address,poi
		showSpinner = true
	}) {
		return axios
			.get(`${MAPBOXAPI}/${term}.json`, {
				params: {
					autocomplete,
					types,
					access_token: MAPBOXKEY,
					showSpinner
				}
			})
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	}
};
