<template>
	<div class="search is-flex" @click="showGlobalSearch()">
		<div class="is-flex">
			<ws-icon icon="search" size="sm" />
			<div class="is-hidden-mobile is-size-7 ml-10">
				{{ $t("components.layouts.app.search.search-for-box") }}
			</div>
		</div>
	</div>
</template>

<script>
import { EventBus } from "@/eventbus";

export default {
	name: "AppSearch",

	data() {
		return {};
	},

	emits: ["showGlobalSearch"],
	methods: {
		showGlobalSearch() {
			EventBus.$emit("showGlobalSearch");
		}
	}
};
</script>

<style lang="scss" scoped>
.search {
	cursor: pointer;
	align-items: center;
	max-width: 354px;
	height: 100%;
	position: relative;
	margin-right: 10px;

	& > div {
		border: 1px solid $color-grey-300;
		padding: 5px 10px;
		border-radius: 5px;
		align-items: center;

		@include mobile {
			padding: 5px;
		}
	}
}
</style>
