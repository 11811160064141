const BoxListMap = () => import("./box-list-map.vue");
export default [
	{
		path: "/boxes-map",
		name: "boxes-map",
		meta: {
			requiresAuth: true
		},
		component: BoxListMap
	}
];
