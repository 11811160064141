<template>
	<div
		class="widget-new-features has-shadow-large"
		v-if="showWidget && lastUpdates.length > 0"
	>
		<div class="message is-small is-info">
			<div class="message-header">
				<p>
					{{
						$t("release-version", {
							version: version
						})
					}}
				</p>
				<ws-button
					is-delete
					class="delete"
					aria-label="close"
					data-testid="close-widget-new-features"
					@click="close"
				/>
			</div>
			<div class="message-body">
				<ul style="list-style: disc; padding-left: 0.5em">
					<li
						v-for="(log, index) of lastUpdates"
						:key="`new-feature-${index}`"
					>
						{{ log }}
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import logs from "@/log-new-release.json";

const LOCALSTORAGE_LAST_RELEASE = "widget-last-release";

export default {
	name: "WidgetNewFeatures",

	data() {
		return {
			showWidget: false
		};
	},

	mounted() {
		const lastVersion = localStorage.getItem(LOCALSTORAGE_LAST_RELEASE);
		if (!lastVersion || lastVersion !== this.version) {
			this.showWidget = true;
		}
	},

	methods: {
		close() {
			if (import.meta.env.MODE !== "development") {
				localStorage.setItem(LOCALSTORAGE_LAST_RELEASE, this.version);
			}
			this.showWidget = false;
		}
	},

	computed: {
		lastUpdates() {
			if (import.meta.env.MODE === "development") {
				return (
					(logs["new-release"] &&
						logs["new-release"][this.$i18n.locale]) || [
						"logs new release not created yet"
					]
				);
			}

			let logsNewRelease =
				logs[this.version] || logs["new-release"] || {};
			logsNewRelease = logsNewRelease[this.$i18n.locale];

			return logsNewRelease || [];
		},

		version() {
			try {
				let version = import.meta.env.VERSION;
				// vite uses "import.meta.env" but jest uses "process.env", so when on test env, get version value from process.env
				if (import.meta.env.MODE === "test") {
					version = process.env.VERSION;
				}

				return version.split("-")[0]; // return the date withou -XX from hotfixes
			} catch {
				return import.meta.env.VERSION || "";
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.widget-new-features {
	position: absolute;
	width: 300px;
	right: 1.75em;
	top: calc(#{$navbar-height} + 0.5em);
	z-index: 30;

	@include mobile {
		width: 90vw;
		right: 0;
		left: 0;
		margin-left: auto;
		margin-right: auto;
	}
}
</style>
