export default [
	{
		path: "/file-resources-management",
		name: "file-resources-management",
		meta: {
			requiresAuth: true
		},
		component: () => import("./file-resources-management.vue")
	}
];
