import objectDiff from "./objectDiff.js";
import {
	isNumber,
	removeReactivityFromObject,
	humanizeWord
} from "@/helpers/functions.helper.js";
import i18n from "@/lang/lang.js";
import { useLogMonitoring } from "@/plugins/user-console-monitoring/useLogMonitoring.js";
import WizardConfigurationService from "@/services/v1/WizardConfiguration.service.js";
import { $log } from "@/plugins/logger/logger.plugin.js";

export function getPropertiesIdFromDeletingMessage(message) {
	const regexMessages = [
		// 1st property = has the redirectToProperties
		// 2nd property(ies) = propertyId(s) on redirectToProperties
		"The property <(.*)> is used as a target for redirection by property <(.*)>",
		"The property <(.*)> has target redirection properties <(.*)>"
		// The property <qBTCVCali9DUG26W> has target redirection properties <[XSKR80e_dHMo24E5]>
	];

	for (const regexMessage of regexMessages) {
		const regex = new RegExp(regexMessage, "gi");
		const resultRegex = regex.exec(message);

		if (resultRegex !== null) {
			return {
				propertyIdRedirected: resultRegex[1],
				propertiesIdsReceivingRedirection: resultRegex[2]
					.replace(/\[|\]/g, "")
					.split(",")
			};
		}
	}

	return {
		propertyIdRedirected: null,
		propertiesIdsReceivingRedirection: []
	};
}

/**
 * If codec is adeunis.modbus_rtu, it will show/hide some configuration fields based on user's appConfig uploaded file
 *
 * @param {string json} appConfig config.appConfig from LoRaWAN equipment
 * @param {object} appAttributes attributes from equipment from equipment (config wizard) database
 * @returns {array} Array with keys to hide from attributes from codec
 */
export async function prepareAttributesForAdeunisModbusRtuConfig(
	appConfig,
	appAttributes
) {
	if (Object.keys(appAttributes || {}).length === 0) {
		appAttributes = await getAttributesFromAdeunisModbus();
	}
	const attributes = removeReactivityFromObject(appAttributes);
	function buildPeriodicDataEnabledList(cfg) {
		try {
			const values = JSON.parse(cfg || "{}")?.values || {};
			const periodicData = {};
			for (
				let registerNumber = 330, dataNumber = 1;
				registerNumber < 350;
				registerNumber++, dataNumber++
			) {
				const slaveAddr =
					parseInt(values[`S${registerNumber}<31:24>`]) || 0;
				periodicData[`data_${dataNumber}_type`] = Boolean(slaveAddr);
			}
			return periodicData;
		} catch (err) {
			useLogMonitoring().notify(
				"[LoRaWAN modbus rtu codec] Failed parsing appConfig",
				{},
				err
			);
			return {};
		}
	}

	const objFieldsConfiguration = buildPeriodicDataEnabledList(appConfig);
	// key = data_XXXX_type
	// value = true|false (tells if attribute should be configured or not by user)
	for (const [key, value] of Object.entries(objFieldsConfiguration)) {
		if (attributes[key]) {
			attributes[key].required = value;
			attributes[key].disabled = !value;
			attributes[key].placeholder = i18n.t("not-configured");
		}
	}

	return attributes;
}

/**
 * Get properties codecIds that aren't mandatory for adeunis.modbus_rtu equipment
 *
 * @param {object} attrs Attributes from adeunis.modbus_rtu codec from equipment database
 * @returns {array} Array with list of codecIds from properties that are NON-required
 */
export function getPropertiesCodecsIdNonMandatoryForAdeunisModbusFromAttributes(
	attrs
) {
	// filter out attributes that are required and return the non-mandatory ones.
	// the list of non-mandatory should be hidden from user as they are useless
	const nonRequiredAttributesCodecIds = [];
	for (const [codecId, config] of Object.entries(attrs)) {
		if (config.required === false) {
			// format of codecId is data_XXXXX_type. convert it to periodic_data.XXXX
			nonRequiredAttributesCodecIds.push(
				codecId.replace("data_", "periodic_data.").replace("_type", "")
			);
		}
	}

	return nonRequiredAttributesCodecIds;
}

async function getAttributesFromAdeunisModbus() {
	try {
		// get all codecs from equipment database
		const availableLorawanCodecs =
			await WizardConfigurationService.getAvailableLorawanCodecs();
		// filter only adeunis.modbus_rtu
		return (
			availableLorawanCodecs.find(
				(codec) => codec.codecId === "adeunis.modbus_rtu"
			)?.configuration?.attributes || {}
		);
	} catch (err) {
		$log.error("failed getting attributes for adeunis", err);
		return {};
	}
}

export function getReadableFieldName(fieldName, isNestedField) {
	let readableFieldName = isNestedField
		? fieldName
				.substring(fieldName.indexOf(".") + 1)
				.slice(0, fieldName.length)
		: fieldName;
	return humanizeWord(readableFieldName);
}
export function isLorawanSystemCodec(codec = "") {
	return (
		[
			"@sys-snr",
			"@sys-rssi",
			"@sys-sf",
			"@sys-frame-cnt",
			"@sys-battery-level"
		].indexOf(codec) > -1
	);
}

export default {
	parseCorrectTypeForFields(obj, typeFields) {
		const clonedObj = { ...obj };

		Object.keys(clonedObj).forEach((key) => {
			let value = clonedObj[key];
			if (value !== null) {
				switch (typeFields[key]) {
					case String:
						value = String(value);
						break;

					case Number:
						value = value != "" ? Number(value) : null;
						break;

					case Boolean:
						value = Boolean(value);
						break;

					case "ArrayNumber":
						value = value
							.split(",")
							.filter((val) => val !== "")
							.map((val) => (isNumber(val) ? Number(val) : null))
							.filter((val) => val !== null);
						break;

					default:
						break;
				}
			}
			clonedObj[key] = value;
		});

		return clonedObj;
	},

	mergeCurrentAndDraftObject(_currentObjects, _draftObjects, objectIdName) {
		if (_currentObjects?.length == 0 && _draftObjects?.length == 0) {
			return [];
		}

		let currentObjects = removeReactivityFromObject(_currentObjects);
		let draftObjects = removeReactivityFromObject(_draftObjects);
		const final = [];

		for (let object of currentObjects) {
			const indexDraftObject = draftObjects.findIndex(
				(obj) => obj[objectIdName] == object[objectIdName]
			);
			// If object is not presented in draft, so it will be deleted from current
			if (indexDraftObject === -1) {
				final.push({ ...object, __status: "deleted" });
				continue;
			}

			// If object has any field different from the draft, it's edited.
			const draftObject = draftObjects[indexDraftObject];
			const areObjectsDifferent = objectDiff.areObjectsDifferent(
				object,
				draftObject
			);
			const status = areObjectsDifferent ? "edited" : "current";
			if (status === "edited") {
				final.push({ ...draftObject, __status: status });
			} else {
				final.push({ ...object, __status: status });
			}
			// Remove object from draftObject
			draftObjects.splice(indexDraftObject, 1);
		}
		// All left objects in draftObject are not presented in current configuration
		for (const object of draftObjects) {
			final.push({ ...object, __status: "new" });
		}

		return final;
	},

	removeScalingObjectIfNecessary(config, showScaling) {
		if (!showScaling) {
			if (config.scaling.a === null && config.scaling.b === 0) {
				delete config.scaling;
			} else {
				config.scaling = {
					a: 1,
					b: 0
				};
			}
		}

		return config;
	},

	createArrayFromObjectList(objects) {
		const final = [];
		for (let object in objects) {
			final.push({
				key: object.toString(),
				value: objects[object]
			});
		}

		return final;
	},

	getAvailableProperties(config, options = {}) {
		const equipments = (config.equipments || []).reduce(
			(acc, equipment) => {
				return {
					...acc,
					[equipment.equipmentId]: equipment.name
				};
			},
			{}
		);
		const gateways = (config.gatewayInterfaces || []).reduce(
			(acc, gateway) => {
				return {
					...acc,
					[gateway.gatewayInterfaceId]: gateway.name
				};
			},
			{}
		);

		return (config.properties || [])
			.filter((prop) =>
				[
					...(options?.allowedAccessTypes || ["REMOTE_READ_ONLY"])
				].includes(prop.accessType)
			)
			.filter(
				(prop) => options?.allowedDisabled || prop.disabled !== true
			)
			.map((prop) => {
				let equipmentName = "";
				if (prop.equipmentId) {
					equipmentName = equipments[prop.equipmentId] || "";
				}
				if (prop.gatewayInterfaceId) {
					equipmentName = gateways[prop.gatewayInterfaceId] || "";
				}
				if (!prop.equipmentId && !prop.gatewayInterfaceId) {
					equipmentName = i18n.t(
						"virtual-properties.virtual-properties"
					);
				}

				const p = {
					label: `${prop.name}${
						equipmentName ? " (" + equipmentName + ")" : ""
					}`,
					value: prop.propertyId,
					equipmentName,
					id: prop.propertyId,
					...prop
				};
				if (options.wsDataTableGroup) {
					const isVirtualProperty =
						!p.equipmentId && !p.gatewayInterfaceId;
					if (isVirtualProperty) {
						p.ws_datatable_group = i18n.t(
							"virtual-properties.virtual-properties"
						);
						p.ws_datatable_group_name = i18n.t(
							"virtual-properties.virtual-properties"
						);
					} else {
						p.ws_datatable_group =
							p.equipmentId !== null
								? p.equipmentId
								: p.gatewayInterfaceId;
						p.ws_datatable_group_name =
							p.equipmentId !== null
								? equipments[p.equipmentId]
								: gateways[p.gatewayInterfaceId];
					}
				}

				if (options.addEquipmentObject) {
					if (p.equipmentId) {
						p.equipment =
							config.equipments.find(
								(equipment) =>
									equipment.equipmentId === p.equipmentId
							) || {};
					}
					if (p.gatewayInterfaceId) {
						p.equipment =
							config.gatewayInterfaces.find(
								(gateway) =>
									gateway.gatewayInterfaceId ===
									p.gatewayInterfaceId
							) || {};
					}
				}

				return p;
			})
			.filter((prop) =>
				options?.noGateways && prop.gatewayInterfaceId ? false : true
			);
	},

	getAvailableEquipments(config, options = {}) {
		let all = [...config.equipments];
		if (!options.noGateways) {
			all = [...all, ...config.gatewayInterfaces];
		}
		return all.map((equip) => ({
			value: equip.equipmentId || equip.gatewayInterfaceId,
			label: equip.name
		}));
	},

	getPropertiesIdFromDeletingMessage: getPropertiesIdFromDeletingMessage,

	getReadableFieldName: getReadableFieldName
};
