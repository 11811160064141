import axios from "axios";

const API = import.meta.env.VITE_API;

import {
	scheduleFromApi,
	stateFromApi,
	periodFromApi,
	exceptionFromApi
} from "../../helpers/scheduler.adapters.js";

export default {
	deviceId: null,
	cache: {},
	currentCache: {},

	setDeviceId(deviceId) {
		this.deviceId = deviceId;
	},

	async publishDraftRevision(notes = null, deviceId = null) {
		return axios
			.put(`${API}/devices/${deviceId || this.deviceId}/configs/draft`, {
				publish: true,
				notes: notes
			})
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async createDraft(deviceId = null) {
		return axios
			.post(`${API}/devices/${deviceId || this.deviceId}/configs`, {})
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async exportDiffAsBlob(deviceId = null) {
		return axios
			.get(
				`${API}/devices/${
					deviceId || this.deviceId
				}/configs/exportDiff`,
				{
					responseType: "blob"
				}
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async getDraft(deviceId = null, params, useCache = false) {
		if (useCache && this.cache[deviceId || this.deviceId]) {
			return Promise.resolve(this.cache[deviceId || this.deviceId]);
		}

		return axios
			.get(`${API}/devices/${deviceId || this.deviceId}/configs/draft`, {
				params
			})
			.then((res) => res.data)
			.then((config) => {
				const data = {
					...config,
					scheduler: {
						...(config.scheduler || {}),
						schedules: (config.scheduler?.schedules || []).map(
							scheduleFromApi
						),
						states: (config.scheduler?.states || []).map(
							stateFromApi
						),
						applicationPeriods: (
							config.scheduler?.applicationPeriods || []
						).map(periodFromApi),
						exceptions: (config.scheduler?.exceptions || []).map(
							exceptionFromApi
						)
					}
				};
				this.cache[deviceId || this.deviceId] = data;
				return data;
			})
			.catch((err) => {
				throw err.response;
			});
	},

	async getCurrent(deviceId = null, params, useCache = false) {
		if (useCache && this.currentCache[deviceId || this.deviceId]) {
			return Promise.resolve(
				this.currentCache[deviceId || this.deviceId]
			);
		}

		return axios
			.get(
				`${API}/devices/${deviceId || this.deviceId}/configs/current`,
				{
					params
				}
			)
			.then((res) => res.data)
			.then((config) => {
				const data = {
					...config,
					scheduler: {
						...(config.scheduler || {}),
						schedules: (config.scheduler?.schedules || []).map(
							scheduleFromApi
						),
						states: (config.scheduler?.states || []).map(
							stateFromApi
						),
						applicationPeriods: (
							config.scheduler?.applicationPeriods || []
						).map(periodFromApi),
						exceptions: (config.scheduler?.exceptions || []).map(
							exceptionFromApi
						)
					}
				};
				this.currentCache[deviceId || this.deviceId] = data;
				return data;
			})
			.catch((err) => {
				throw err.response;
			});
	},

	async getDataPointsCount(deviceId = null, revision = "draft") {
		return axios
			.get(
				`${API}/devices/${
					deviceId || this.deviceId
				}/configs/${revision}/dataPoints/count`,
				{
					params: {
						showSpinner: false
					}
				}
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async getVirtualPropertiesDataPointsCount(
		deviceId = null,
		revision = "draft"
	) {
		return axios
			.get(
				`${API}/devices/${
					deviceId || this.deviceId
				}/configs/${revision}/virtualPropertiesCount`,
				{
					params: {
						showSpinner: false
					}
				}
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async deleteDraft(deviceId = null) {
		return axios
			.delete(`${API}/devices/${deviceId || this.deviceId}/configs/draft`)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async getDraftEquipment(deviceId = null, params) {
		const _deviceId = deviceId || this.deviceId;
		return axios
			.get(`${API}/devices/${_deviceId}/configs/draft/equipments`, {
				params
			})
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async getDraftProperties(deviceId = null, params) {
		return axios
			.get(
				`${API}/devices/${
					deviceId || this.deviceId
				}/configs/draft/properties`,
				{
					params
				}
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async getDraftNetworks(deviceId = null, params) {
		return axios
			.get(
				`${API}/devices/${
					deviceId || this.deviceId
				}/configs/draft/networks`,
				{
					params
				}
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async getCurrentProperties(deviceId = null, params) {
		const _deviceId = deviceId || this.deviceId;
		return axios
			.get(`${API}/devices/${_deviceId}/configs/current/properties`, {
				params
			})
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async uploadDAconfigFileToCloudHub(formData = null, deviceId = null) {
		const _deviceId = deviceId || this.deviceId;
		return axios
			.post(
				`${API}/devices/${_deviceId}/configs/draft/import?force=true`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data"
					}
				}
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async exportHubConfiguration(deviceId = null) {
		const _deviceId = deviceId || this.deviceId;

		return axios
			.get(`${API}/devices/${_deviceId}/configs/current/export`)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async importHubConfiguration(formData = null, deviceId = null) {
		const _deviceId = deviceId || this.deviceId;

		return axios
			.post(
				`${API}/devices/${_deviceId}/configs/draft/import`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data"
					}
				}
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	}
};
