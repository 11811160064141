<template>
	<a class="navbar-item" ref="aElement" v-if="isChatReady">
		<span>
			{{ $t("chat") }}
		</span>
		<span class="icon">
			<ws-icon icon="chat" />
		</span>
	</a>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

import { useClientName } from "@/plugins/client-name/client-name.plugin.js";

const isChatReady = ref(false);
const aElement = ref(null);
const { clientName } = useClientName();
const isSiemens = computed(() => clientName === "siemens");

if (isSiemens.value) {
	isChatReady.value = true;
}

onMounted(() => {
	if (!isSiemens.value) {
		return;
	}
	aElement.value.href = "https://support.industry.siemens.com/cs/my?lc=en-WW";
	aElement.value.target = "_blank";
	aElement.value.rel = "noopener";
});
</script>
