export default (_, c) => {
	// locale needed later
	const proto = c.prototype;
	const oldFormat = proto.format;
	proto.format = function (formatStr) {
		const locale = this.$locale();

		if (!this.isValid()) {
			return oldFormat.bind(this)(formatStr);
		}

		const str = formatStr || "YYYY-MM-DDTHH:mm:ssZ";
		let result = str;
		if (str === "WS-DT") {
			if (locale.name === "fr") {
				result = "dddd D MMMM [à] H[h]";
			} else if (locale.name === "de") {
				result = "dddd, D. MMMM [um] H:mm [Uhr]";
			} else {
				result = "dddd, MMMM D [at] h:mm a";
			}
		}
		if (str === "WS-LD") {
			if (locale.name === "fr") {
				result = "dddd D MMM YYYY";
			} else {
				result = "ddd MMM D YYYY";
			}
		}
		return oldFormat.bind(this)(result);
	};
};
