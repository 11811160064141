const AlarmHistoryDetails = () =>
	import(
		"@/views/box/views/alarms/components/alarm-history/alarm-history-details.vue"
	);
const SelectAlarm = () =>
	import(
		"@/views/box/views/alarms/components/alarm-history/select-alarm.vue"
	);

export default [
	{
		path: "/alarms",
		name: "alarms",
		meta: {
			requiresAuth: true
		},
		component: () => import("./alarms.vue"),
		children: [
			{
				name: "alarms-list",
				path: "/",
				component: SelectAlarm
			},
			{
				name: "alarms-list-history-item",
				path: ":alarmId",
				component: AlarmHistoryDetails
			}
		]
	}
];
