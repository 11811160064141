import Toast from "vue-toastification";
import { useToast } from "vue-toastification";
import WsToast from "./ws-toast.vue";

const options = {
	position: "top-right",
	showCloseButtonOnHover: true,
	timeout: 5000,
	icon: false,
	closeButton: null,
	draggable: false,
	hideProgressBar: true,
	shareAppContext: true,
	pauseOnHover: true,
	newestOnTop: true,
	transition: "ws-toast__fade"
};

const createToastMethod = (_toastType = "") => {
	// default => success
	const toastType = ["success", "error", "info", "warning"].includes(
		_toastType
	)
		? _toastType
		: "success";

	const style = [
		"success",
		"error",
		"danger",
		"info",
		"warning",
		"default",
		"grey",
		"primary",
		"complementary"
	].includes(_toastType)
		? _toastType
		: "success";

	return (_args, _options = {}) => {
		const { showIcon = true, ...otherOptions } = _options || {};
		let text = "";

		if (typeof _args === "string") {
			text = _args;
		}
		return toasted.Toast[toastType](
			{
				component: WsToast,
				props: {
					content: text,
					type: style,
					showIcon
				}
			},
			{
				toastClassName: `ws-toast-${style}`,
				...otherOptions
			}
		);
	};
};

const dismissMethod = () => {
	return (toastId) => {
		return toasted.Toast.dismiss(toastId);
	};
};
const updateMethod = () => {
	return (toastId, _options) => {
		return toasted.Toast.update(toastId, _options);
	};
};
const toasted = {
	Toast: null,
	dismiss: dismissMethod(),
	update: updateMethod(),
	info: createToastMethod("info"),
	success: createToastMethod("success"),
	primary: createToastMethod("primary"),
	error: createToastMethod("error"),
	danger: createToastMethod("error"),
	warning: createToastMethod("warning"),
	default: createToastMethod("default"),
	grey: createToastMethod("default"),
	complementary: createToastMethod("default")
};
export const $toasted = toasted;
export const useWsToast = () => toasted;

export default {
	install(app) {
		app.use(Toast, options);
		toasted.Toast = useToast();
		app.config.globalProperties.$toasted = toasted;
	}
};
