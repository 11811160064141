import emitter from "tiny-emitter/instance";
export const bus = {
	$on: (...args) => emitter.on(...args),
	$once: (...args) => emitter.once(...args),
	$off: (...args) => emitter.off(...args),
	$emit: (...args) => emitter.emit(...args)
};

let initZendeskChat;
const WS_ZN_KEY = "4630d860-3102-4884-86d3-b545af86ed23";

export const useZendeskChat = () => ({
	bus,
	initZendeskChat
});

function attachUnreadMsgListener() {
	if (!window.zE) {
		setTimeout(() => attachUnreadMsgListener(), 500);
		return;
	}
	window.zE("messenger:on", "unreadMessages", (count) => {
		bus.$emit("unreadMessages", count);
	});
}

export default {
	install() {
		initZendeskChat = function init(initArgs = null) {
			const zendesk = document.createElement("script");
			zendesk.type = "text/javascript";
			zendesk.id = "ze-snippet";
			zendesk.defer = true;
			zendesk.async = true;
			zendesk.src = `https://static.zdassets.com/ekr/snippet.js?key=${WS_ZN_KEY}`;

			const ze_script = document.getElementsByTagName("script")[0];
			ze_script.parentNode.insertBefore(zendesk, ze_script);
			bus.$emit("loaded");

			if (initArgs.launcherBtn) {
				initArgs.launcherBtn.addEventListener("click", () => {
					// open widget
					window.zE("messenger", "open");
					bus.$emit("unreadMessages", 0);
				});
				attachUnreadMsgListener();
			}
		};
	}
};
