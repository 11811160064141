import WsToast from "./toast/toast.plugin.js";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import Vue3TouchEvents from "vue3-touch-events";
import WsPermission from "./ws-permission/ws-permission.plugin.js";
import Dayjs from "./dayjs/dayjs.plugin.js";
import ZendeskChat from "./chat/zendesk-chat.plugin.js";
import ClientName from "./client-name/client-name.plugin.js";
import VueSplit from "./vue-split/vue-split.plugin.js";
import Logger from "./logger/logger.plugin.js";
import WsPageTitle from "./page-title/page-title.plugin.js";
import KeepSessionPlugin from "./keep-session/keep-session.plugin.js";
import UserConsoleMonitoringPlugin from "./user-console-monitoring/user-console-monitoring.plugin.js";

import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import iconUrl from "leaflet/dist/images/marker-icon.png";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";

export default {
	install(app) {
		app.use(KeepSessionPlugin);
		app.use(Vue3TouchEvents);
		app.use(VueSplit);
		app.use(ClientName);

		app.use(WsToast);

		// Leaflet map
		delete Icon.Default.prototype._getIconUrl; // this part resolve an issue where the markers would not appear
		Icon.Default.mergeOptions({
			iconRetinaUrl,
			iconUrl,
			shadowUrl
		});

		app.use(WsPermission);
		app.use(Dayjs);
		app.use(ZendeskChat);
		app.use(Logger, {
			isEnabled: import.meta.env.NODE_ENV !== "production",
			logLevel: "debug",
			showConsoleColors: true
		});
		app.use(UserConsoleMonitoringPlugin, {
			isEnabled: import.meta.env.PROD
		});

		app.use(WsPageTitle);
	}
};
