<template>
	<div
		v-if="isWidgetVisible"
		id="zendesk-launcher"
		ref="zendeskLauncherRef"
		class="zendeskLauncher is-clickable"
		@loaded="loaded"
	>
		<ws-icon icon="chat" />
		<span class="badge" v-if="unreadMessages">{{ unreadMessages }}</span>
	</div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useZendeskChat } from "@/plugins/chat/zendesk-chat.plugin.js";
import { useLogger } from "@/plugins/logger/logger.plugin.js";
import { useAuth } from "@/composables/useAuth.js";
import { useI18n } from "vue-i18n";
import { useClientName } from "@/plugins/client-name/client-name.plugin.js";

const { bus: chatBus, initZendeskChat } = useZendeskChat();
const zendeskLauncherRef = ref(null);
const isWidgetVisible = ref(true);
const unreadMessages = ref(null);
const $log = useLogger();
const { locale } = useI18n();
const { user, isDemo } = useAuth();
const { clientName } = useClientName();
const isSiemens = computed(() => clientName === "siemens");

if (isSiemens.value) {
	isWidgetVisible.value = false;
}

const initializeChat = () => {
	const { username, fullName, language } = user;
	initZendeskChat({
		name: isDemo ? fullName : null,
		email: isDemo ? username : null,
		language: language || locale,
		launcherBtn: zendeskLauncherRef.value
	});
};
onMounted(() => {
	// init chat if on dev env (for testing)
	// or on production when not Siemens
	if (
		import.meta.env.DEV ||
		(clientName !== "siemens" && import.meta.env.PROD)
	) {
		try {
			initializeChat();
		} catch (err) {
			$log.error("failed initializing chat", err);
		}
	}
});

chatBus.$on("unreadMessages", (count) => {
	if (!count) {
		unreadMessages.value = null;
	}
	unreadMessages.value = count > 9 ? "9+" : count;
});
</script>

<style lang="scss" scoped>
$footerButtonBottom: 16px;
$footerButtonHeight: 60px;
$chatWidgetWidth: 45px;

.zendeskLauncher {
	border: 1px solid $color-primary-500;
	color: $color-primary-500;
	background-color: $white;
	z-index: 998;
	border-radius: 100%;
	position: fixed;
	right: 16px;
	bottom: calc(
		$footerButtonBottom + (($footerButtonHeight - $chatWidgetWidth) / 2)
	);
	width: $chatWidgetWidth;
	height: $chatWidgetWidth;
	padding: 10px;
	box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
}
.zendeskLauncher:hover {
	background-color: $color-primary-500;
	border-color: $color-primary-500;
	color: $white;
}
.badge {
	position: absolute;
	right: -8px;
	top: -8px;
	min-width: 20px;
	min-height: 20px;
	line-height: 20px;
	text-align: center;
	color: $white;
	background-color: $color-danger-500;
	font-size: 10px;
	border-radius: 40px;
}
</style>
