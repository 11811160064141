<template>
	<div id="app">
		<section class="auth">
			<div class="container has-text-centered">
				<div class="columns is-centered">
					<div
						class="column is-12-mobile is-8-tablet is-10-desktop is-8-widescreen is-size-7"
					>
						<figure class="image">
							<img alt="full-logo" :src="getLogoImg" />
						</figure>

						<slot />
					</div>
				</div>
			</div>
		</section>

		<div class="languageSelector">
			<ws-language-selector
				@update:model-value="changedLanguage"
				v-model="currentLanguage"
			/>
		</div>
	</div>
</template>

<script>
import { getBigLogoUrl } from "@/plugins/client-name/client-name.plugin.js";

export default {
	name: "AuthLayout",

	data() {
		return {
			getLogoImg: null,
			currentLanguage: "en"
		};
	},
	async created() {
		this.getLogoImg = await getBigLogoUrl();
		const browserLang =
			navigator.languages[0] || navigator.language || "en";
		this.currentLanguage = browserLang.slice(0, 2);
		this.changedLanguage(this.currentLanguage);
	},
	methods: {
		changedLanguage(newLocale) {
			this.$i18n.locale = newLocale;
			this.$dayjsSetLocale(newLocale);
		}
	}
};
</script>

<style lang="scss" scoped>
.auth {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	height: 100vh;
	overflow-y: auto;
	padding-top: 5em;
	margin-left: 0.75rem;

	figure {
		margin-bottom: 25px;
		display: flex;
		justify-content: center;
		img {
			max-width: 350px;
			min-height: 75px;
		}
	}
}
.languageSelector {
	position: absolute;
	right: 0.5rem;
	top: 0.5rem;
}
</style>
