export default {
	areObjectsDifferent(obj1, obj2) {
		if (isValue(obj1) || isValue(obj2)) {
			return !sameValue(obj1, obj2);
		}

		for (const key in obj1) {
			let value2 = undefined;
			if (typeof obj2[key] != "undefined") {
				value2 = obj2[key];
			}

			if (this.areObjectsDifferent(obj1[key], value2)) {
				return true;
			}
		}
		for (const key in obj2) {
			// If there's the same object in obj1, keep looping as obj1[key] == obj2[key]
			if (typeof obj1[key] != "undefined") {
				continue;
			}
			// If reach this point, means obj2 has different keys than obj1
			return true;
		}

		return false;
	}
};

function isArray(obj) {
	return {}.toString.apply(obj) === "[object Array]";
}
function isObject(obj) {
	return {}.toString.apply(obj) === "[object Object]";
}
function isValue(obj) {
	return !isObject(obj) && !isArray(obj);
}
function sameValue(value1, value2) {
	return value1 === value2;
}
