<template>
	<div class="sidebar-mobile" ref="sidebarMobile">
		<div class="sidebar-mobile-overlay" v-if="showMenu">
			<div
				class="sidebar-mobile-overlay-bg"
				@click="$emit('toggle-show-menu', $event)"
			></div>
		</div>

		<div class="close" v-if="showMenu">
			<span
				type="button"
				class="button close-button"
				@click="$emit('toggle-show-menu', $event)"
			>
				<ws-icon icon="close" size="sm" />
			</span>
		</div>

		<transition
			name="animate-sidebar"
			@before-enter="toggleVisibleMainDiv"
			@after-leave="toggleVisibleMainDiv"
		>
			<div class="items" v-if="showMenu">
				<slot />
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	name: "SidebarMobile",

	emits: ["toggle-show-menu"],

	props: {
		showMenu: {
			type: Boolean,
			default: false
		}
	},

	methods: {
		toggleVisibleMainDiv() {
			this.$refs.sidebarMobile.classList.toggle("visible");
		}
	}
};
</script>

<style lang="scss" scoped>
@mixin inset($amount) {
	top: $amount;
	bottom: $amount;
	left: $amount;
	right: $amount;
}

.sidebar-mobile {
	display: flex;
	position: fixed;
	z-index: 40;
	height: 100%;
	@include inset(0);
	left: -100%;

	&.visible {
		left: 0;
	}

	&-overlay {
		@include inset(0);
		position: fixed;

		&-bg {
			@include inset(0);
			position: absolute;
			opacity: 0.75;
			background-color: $black;
		}
	}

	.items {
		position: relative;
		display: flex;
		height: 100%;
		z-index: 100;
		transform: translateX(0);

		&.animate-sidebar-enter-from,
		&.animate-sidebar-leave-to {
			transform: translateX(-100%);
		}

		&.animate-sidebar-enter-active,
		&.animate-sidebar-leave-active {
			transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
		}
	}

	.close {
		display: block;
		position: absolute;
		top: 5px;
		left: 225px;
		&-button {
			color: $white;
			background-color: transparent;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 2em;
			width: 2em;
			border-radius: 9999px;
			border: none;

			&:hover {
				background-color: $grey;
			}

			&:focus {
				outline: 2px solid transparent;
				outline-offset: 2px;
			}
		}
	}
}
</style>
