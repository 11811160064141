import axios from "axios";

const API = import.meta.env.VITE_API;

export default {
	deviceId: null,

	setDeviceId(deviceId) {
		this.deviceId = deviceId;
	},

	async getAllFromRevision(revision = "current", deviceId = null) {
		return axios
			.get(
				`${API}/devices/${
					deviceId || this.deviceId
				}/configs/${revision}/connectors`
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async get(connector, revision = "current", deviceId = null) {
		return axios
			.get(
				`${API}/devices/${
					deviceId || this.deviceId
				}/configs/${revision}/connectors/${connector}`
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async update(physicalConnector, params, deviceId = null) {
		return axios
			.put(
				`${API}/devices/${
					deviceId || this.deviceId
				}/configs/draft/connectors/${physicalConnector}`,
				params
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async delete(physicalConnector, deviceId = null) {
		return axios
			.delete(
				`${API}/devices/${
					deviceId || this.deviceId
				}/configs/draft/connectors/${physicalConnector}`
			)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	}
};
