<template>
	<ws-notification-message
		v-if="deviceStore.oldRvlEquipments.length"
		type="info"
		light
		class="m-0 p-3"
		:show-close-button="true"
		show-icon
	>
		{{ $t("rvl-migration-needed-msg") }}
		<template #footer>
			<ws-text-link
				:show-icon="false"
				is-link
				@click="queueMigration"
				class="p-0"
			>
				{{ $t("rvl-migration-click-to-update") }}
			</ws-text-link>
		</template>
	</ws-notification-message>
</template>
<script>
import { useDeviceStore } from "@/store/device";
import { mapStores } from "pinia";

export default {
	name: "RvlMigrationBanner",

	methods: {
		async queueMigration() {
			try {
				const resp = await this.deviceStore.queueRvlMigrationTasks(
					this.deviceStore.oldRvlEquipments
				);
				if (resp) {
					this.$toasted.success(this.$t("rvl-migration-success"));
				}
			} catch (error) {
				this.$log.error("failed queueMigration", error);
			}
		}
	},
	computed: {
		...mapStores(useDeviceStore)
	}
};
</script>
