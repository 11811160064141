import axios from "axios";
import AuthService from "@/services/Auth.service.js";
import { EventBus } from "@/eventbus.js";
import router from "../router.js";

let numRequests = 0; // qtt of running requests to show/hide global loading spinner

function startedRequest() {
	numRequests++;
	EventBus.$emit("xhr", true);
}

function finishedRequest() {
	numRequests--;
	if (numRequests <= 0) {
		numRequests = 0;
		EventBus.$emit("xhr", false);
	}
}

EventBus.$on("addXhr", startedRequest);
EventBus.$on("removeXhr", finishedRequest);

function convertParamsInStr(params) {
	if (!params) {
		return "";
	}

	return Object.keys(params).reduce((acc, cur, idx) => {
		if (idx !== 0) {
			acc += "&";
		}
		return acc + cur + "=" + params[cur];
	}, "?");
}

function isUrlInBlacklist(url) {
	const blacklistUrls = [
		"/discovery/bacnet/",
		"/commands?commandType=SET_PROPERTY&size=1000&since=-1",
		"/updates/actions",
		"showSpinner=false",
		"/virtualPropertiesCount",
		"/dataPoints/count"
	];
	if (
		blacklistUrls.find(
			(blacklistedUrl) => url.indexOf(blacklistedUrl) !== -1
		)
	) {
		return true;
	}

	return false;
}

export default async function axiosHelper() {
	const AUTH_TOKEN = import.meta.env.VITE_AUTH_TOKEN;
	const API = import.meta.env.VITE_API;

	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem(AUTH_TOKEN);
			if (token) {
				config.headers = {
					...(config.headers || {}),
					"X-Auth-Token": `${token}`,
					"Accept-Language":
						AuthService.user()?.language ||
						window.navigator.language
				};
			}

			// axios v1.x uses as default a seializer that will output the array indexes as indexes[]=0&indexes[]=1, while BE doesn't accept well this format. By passing a null serializer, we can use the indexes as indexes=0&indexes=1
			config.paramsSerializer = {
				indexes: null
			};

			const fullUrl = config.url + convertParamsInStr(config.params);
			if (!isUrlInBlacklist(fullUrl)) {
				startedRequest();
			}

			// remove the showSpinner parameter that's used only on the console
			if (
				config.params &&
				Object.keys(config.params).includes("showSpinner")
			) {
				delete config.params.showSpinner;
			}

			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	axios.interceptors.response.use(
		(response) => {
			const config = response.config;
			const fullUrl = config.url + convertParamsInStr(config.params);
			if (!isUrlInBlacklist(fullUrl)) {
				finishedRequest();
			}

			if (
				["post", "put", "delete"].includes(config.method ?? "") &&
				(config.url ?? "").includes("/configs/")
			) {
				EventBus.$emit("changedConfiguration");
			}

			// Response data

			return response;
		},
		async (error) => {
			const currentRoute = router.currentRoute?.value;

			// no need to -1 numRequests if in the request section we did not have a +1!!
			if (!isUrlInBlacklist(error.request.responseURL)) {
				finishedRequest();
			}

			// If get 401 as response logout user
			const isUnauthorized = error?.response?.status === 401;
			// Catch error on Redis session
			const documentFailed =
				error?.response.status === 500 &&
				(error.request.responseURL.endsWith("/console") ||
					error.request.responseURL === `${API}/user`);

			if (isUnauthorized || documentFailed) {
				// If get a 401 response when informing the code to setup the account, just return the error
				if (
					window.location.pathname.indexOf("/setup/code") > -1 ||
					window.location.pathname.indexOf("/activation") > -1
				) {
					return Promise.reject(error);
				}
				await AuthService.logout();

				// If isn't in login page, redirect user
				if (currentRoute.name !== "login") {
					router.replace({
						name: "login",
						query: {
							redirect:
								currentRoute?.fullPath ||
								// currentRoute._rawValue?.fullPath ||
								window.location.pathname
						}
					});
				}
			}

			return Promise.reject(error);
		}
	);
}
