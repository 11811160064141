const Login = () => import("./login.vue");
const RequestNewPassword = () => import("./request-new-password.vue");
const ResetPassword = () => import("./reset-password.vue");
const SetupCode = () => import("./onboarding/setup-code.vue");
const SetupPassword = () => import("./onboarding/setup-password.vue");
const SetupOrganization = () => import("./onboarding/setup-organization.vue");
const SetupOrganizationUsers = () =>
	import("./onboarding/setup-organization-users.vue");
const SetupOrganizationContacts = () =>
	import("./onboarding/setup-organization-contacts.vue");
const SetupFinished = () => import("./onboarding/setup-finished.vue");
const SetupActivationUser = () =>
	import("./onboarding/setup-activation-user.vue");

export default [
	{
		path: "/login",
		name: "login",
		component: Login,
		meta: {
			layout: "auth"
		}
	},
	{
		path: "/activation",
		name: "activation",
		component: SetupActivationUser,
		meta: {
			layout: "auth"
		}
	},
	{
		path: "/password/reset",
		name: "request-new-password",
		component: RequestNewPassword,
		meta: {
			layout: "auth"
		}
	},
	{
		path: "/password/new",
		name: "reset-password",
		component: ResetPassword,
		meta: {
			layout: "auth"
		}
	},
	{
		path: "/setup/code",
		name: "setup-code",
		component: SetupCode,
		meta: {
			layout: "auth"
		}
	},
	{
		path: "/setup/password",
		name: "setup-password",
		component: SetupPassword,
		meta: {
			layout: "auth"
		}
	},
	{
		path: "/setup/organization",
		name: "setup-organization",
		component: SetupOrganization,
		meta: {
			layout: "auth"
		}
	},
	{
		path: "/setup/organization/users",
		name: "setup-organization-users",
		component: SetupOrganizationUsers,
		meta: {
			layout: "auth"
		}
	},
	{
		path: "/setup/organization/contacts",
		name: "setup-organization-contacts",
		component: SetupOrganizationContacts,
		meta: {
			layout: "auth"
		}
	},
	{
		path: "/setup/finished",
		name: "setup-finished",
		component: SetupFinished,
		meta: {
			layout: "auth"
		}
	}
];
