import AuthService from "@/services/Auth.service.js";
import { computed } from "vue";

export function useAuth() {
	const user = AuthService.user();
	const isDemo = AuthService.isDemo();
	const isReadOnlyUser = AuthService.isReadOnlyUser();
	const isWattsense = AuthService.isWattsense();
	const isAdmin = computed(() =>
		// First privileges object is for default organization.
		Object.values(user.privileges)[0].find(
			(privilege) => privilege.action === "Admin"
		)
	);

	function isAdminOnOrg(organizationId) {
		return (
			organizationId &&
			!!user.privileges[organizationId]?.find(
				(privilege) => privilege.action === "Admin"
			)
		);
	}

	return {
		user,
		isDemo,
		isReadOnlyUser,
		isWattsense,
		isAdmin,
		isAdminOnOrg
	};
}
