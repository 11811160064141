import axios from "axios";

const API = import.meta.env.VITE_API;

export default {
	/**
	 *
	 * @param {string} ownershipType BUYER or SELLER
	 * @returns
	 */
	async list({ organizationId = null, ownershipType = null }) {
		const queryParams = {};
		if (organizationId !== null) {
			queryParams.organizationId = organizationId;
		}
		if (ownershipType !== null) {
			queryParams.ownershipType = ownershipType;
		}

		return axios
			.get(`${API}/ratePlans`, {
				params: queryParams
			})
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async create(infos) {
		return axios
			.post(`${API}/ratePlans`, infos)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async delete(ratePlanId) {
		return axios
			.delete(`${API}/ratePlans/${ratePlanId}`)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async pairWithHardware({ ratePlanOrderId, hardwareId, crc }) {
		return axios
			.put(`${API}/ratePlans/pair`, { ratePlanOrderId, hardwareId, crc })
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	}
};
