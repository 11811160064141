export default [
	{
		path: "/list-boxes",
		name: "boxes",
		meta: {
			requiresAuth: true
		},
		component: () => import("./box-list.vue")
	}
];
