<template>
	<div class="toast-wrapper">
		<div :class="`toast-icon ${iconColor}`" v-if="props.showIcon && icon">
			<ws-icon :icon="icon" />
		</div>
		<div class="toast-content">
			{{ props.content }}
		</div>
		<div class="toast-close" v-if="showCloseButton">
			<ws-icon
				icon="close"
				size="sm"
				@click="emits('close-toast')"
				data-testid="button-close-toast"
			/>
		</div>
	</div>
</template>
<script setup>
import { computed } from "vue";
const emits = defineEmits(["close-toast"]);
const props = defineProps({
	content: {
		type: String,
		default: () => ""
	},
	type: {
		type: String,
		default: "success",
		validation: function (value) {
			return [
				"info",
				"success",
				"error",
				"warning",
				"default",
				"danger",
				"grey",
				"primary",
				"complementary"
			].includes(value);
		}
	},
	showIcon: {
		type: Boolean,
		default: true
	},
	showCloseButton: {
		type: Boolean,
		default: false
	}
});
const icon = computed(() => {
	switch (props.type) {
		case "complementary":
		case "primary":
		case "success":
			return "check";
		case "warning":
		case "error":
		case "danger":
			return "alert";
		case "default":
		case "info":
		default:
			return "info";
	}
});

const iconColor = computed(() => {
	switch (props.type) {
		case "info":
			return "has-text-info";
		case "default":
			return "has-text-black";
		default:
			return "has-text-white";
	}
});
</script>
<style lang="scss" scoped>
.toast-wrapper {
	width: 100%;
	max-width: 100%;
	display: flex;

	.toast-icon {
		padding-right: 1rem;
	}
	.toast-content {
		width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
	}
	.toast-close {
		padding-left: 0.5rem;
		cursor: pointer;
	}
}
</style>
