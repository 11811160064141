export function truncate(text = "", stop = 30, clamp = "...") {
	if (!text) {
		return "";
	}
	return text.slice(0, stop) + (stop < text.length ? clamp : "");
}

export default {
	install(app) {
		app.config.globalProperties.$filters = {
			truncate: truncate
		};
	}
};
