<template>
	<div
		class="modal is-active"
		v-show="showGlobalSearch"
		@keyup.esc="close"
		tabindex="0"
	>
		<div class="modal-background" />
		<div class="modal-card">
			<header class="modal-card-head">
				<div class="modal-card-title">
					<p
						class="control is-expanded has-icons-left has-icons-right"
					>
						<input
							:placeholder="
								$t(
									'components.layouts.app.search.search-for-box'
								)
							"
							ref="searchInput"
							v-ws-focus
							autofocus
							v-model="searchTerm"
							class="input"
							type="text"
							autocomplete="off"
							:disabled="loading"
						/>
						<span class="icon is-left">
							<ws-icon size="sm" icon="search" />
						</span>
						<span class="icon is-right is-small">
							<button
								type="button"
								class="delete is-small"
								@click="clearSearch()"
							/>
						</span>
					</p>
				</div>
				<span
					data-testid="global-search-modal-close-btn"
					class="delete close-modal"
					aria-label="close"
					@click="close()"
				/>
			</header>
			<section class="modal-card-body">
				<template v-if="!loading">
					<tabs :results="filteredBoxes.length || 0" />
					<boxes-result
						:boxes="filteredBoxes || []"
						v-if="activeTab === 'box'"
					/>
				</template>
				<p v-if="loading">{{ $t("loading") }} ...</p>
			</section>
		</div>
	</div>
</template>

<script>
import { EventBus } from "@/eventbus.js";
import DeviceService from "@/services/v1/Device.service.js";
import Tabs from "./tabs.vue";
import BoxesResult from "./boxes-result.vue";
import { filterBoxesByTerm, prepareTermToSearch } from "./search.helper.js";

export default {
	name: "ModalGlobalSearch",

	data() {
		return {
			showGlobalSearch: false,

			boxes: [],
			filteredBoxes: [],
			equipment: [],
			boxesNames: {},
			equipmentDictionary: [],

			activeTab: "box",
			searchTerm: "",
			typeResultSearch: null,

			loading: false
		};
	},

	watch: {
		loading: function (_newValue) {
			if (_newValue === false) {
				this.focusOnSearchInput();
			}
		},

		searchTerm: function () {
			this.runSearch();
		},

		$route: function () {
			this.close();
		}
	},

	created() {
		EventBus.$on("showGlobalSearch", () => {
			this.showModal();
			this.loadAllBoxes();
		});
	},

	unmounted() {
		EventBus.$off("showGlobalSearch");
	},

	methods: {
		focusOnSearchInput() {
			this.$nextTick(() => {
				this.$refs.searchInput.focus();
			});
		},

		changedTab(tab) {
			this.activeTab = tab;
			this.focusOnSearchInput();
		},

		async loadAllBoxes() {
			if (this.loading) {
				return;
			}
			this.loading = true;
			await this.getAllBoxes();
			this.boxesNames = this.boxes.reduce((acc, box) => {
				return {
					...acc,
					[box.deviceId]: box.name
				};
			}, {});
			this.loading = false;
			this.runSearch();
		},

		showModal() {
			this.showGlobalSearch = true;
			this.$nextTick(() => {
				this.$refs.searchInput.focus();
			});
		},

		close() {
			this.showGlobalSearch = false;
		},

		async getAllBoxes() {
			try {
				const boxes = await DeviceService.getDevices({
					showSpinner: false,
					lifecycles: ["ACTIVATED"]
				});
				this.boxes = boxes.map((box) => {
					// online = 0; offline = 1; other = 2
					// this is used to sort boxes by status
					let status = -1;
					switch (
						box?.connectivityStatus?.connectionStatusInfo?.status
					) {
						case "ONLINE":
							status = 0;
							break;

						case "OFFLINE":
							status = 1;
							break;

						default:
							status = 2;
							break;
					}
					return {
						...box,
						_status: status
					};
				});
			} catch {
				this.boxes = [];
				this.$toasted.error(
					this.$t(
						"components.layouts.app.components.global-search.modal-globlal-search.failed-getting-boxes"
					)
				);
			}
		},

		runSearch() {
			const term = prepareTermToSearch(this.searchTerm);
			this.filteredBoxes = filterBoxesByTerm(this.boxes, term);
		},

		clearSearch() {
			this.searchTerm = "";
		}
	},

	components: {
		Tabs,
		BoxesResult
	}
};
</script>

<style lang="scss" scoped>
svg {
	width: 100%;
	height: 100%;
}

.modal-card {
	width: calc(100vw - 150px);

	@include mobile {
		width: calc(100vw - 50px);
	}

	.modal-card-head {
		.modal-card-title {
			display: inline-flex;
			align-items: center;

			& > :first-child {
				flex-grow: 2;
			}

			p.control {
				margin-right: 10px;

				.icon.is-left {
					color: $color-grey-400 !important;
				}

				input {
					background-color: $white;
					&::placeholder {
						color: $color-grey-400 !important;
					}
				}
			}
		}

		.close-modal {
			max-height: 32px;
			max-width: 32px;
			height: 32px;
			width: 32px;
			border-radius: 20%;
		}
	}
}
</style>
