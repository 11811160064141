import "./index.scss";

import wsHasPermission from "./ws-has-permission.directive.js";
import wsHasNoPermission from "./ws-has-no-permission.directive.js";
import wsHasAppsVersionGreater from "./ws-has-apps-version-greater.directive.js";

export const compareVersion = (v1, v2) => {
	if (typeof v1 !== "string") {
		return -2;
	}
	if (typeof v2 !== "string") {
		return -2;
	}
	v1 = v1.split(".");
	v2 = v2.split(".");
	const k = Math.min(v1.length, v2.length);
	for (let i = 0; i < k; ++i) {
		v1[i] = parseInt(v1[i], 10);
		v2[i] = parseInt(v2[i], 10);
		if (v1[i] > v2[i]) {
			return 1;
		}
		if (v1[i] < v2[i]) {
			return -1;
		}
	}
	if (v1.length == v2.length) {
		return 0;
	}
	if (v1.length < v2.length) {
		return -1;
	}
	return 1;
};

export const auth = {
	// ex: [
	//     { level: "user", moduleName: "*" },
	//     { level: "subscription", moduleName: "alarms" },
	//     { level: "subscription", moduleName: "minibms" }
	// ],
	perms: [],
	appVersion: null,
	// ex: setPermissions with level="user" & modules=["*"]
	// ex: setPermissions with level="subscription" & modules=["alarms", "heating_optimizer"]
	setPermissions: (level, modules) => {
		if (!level) {
			return;
		}
		auth.perms = [...auth.perms.filter((perm) => perm.level !== level)];
		modules.forEach((m) => auth.perms.push({ level, moduleName: m }));
	},
	// ex: getPermissions() => return all
	// ex: getPermissions("subscription") => return only specific perms
	getPermissions: (level = null) => {
		if (!level) {
			return [...auth.perms];
		}
		return [...auth.perms.filter((p) => p.level === level)];
	},
	// ex: hasPermissionTo with level="user"
	// ex: hasPermissionTo with level="subscription" & moduleName="alarms"
	hasPermissionTo: (level, _moduleName = "*") => {
		if (!auth.getPermissions() || !_moduleName) {
			return null;
		}
		let exist = "T";
		let moduleName = _moduleName;
		if (moduleName.indexOf("!") === 0) {
			exist = "F";
			moduleName = _moduleName.split("!")[1];
		}
		const perms = auth.getPermissions().filter((perm) => {
			return (
				(perm.level === level &&
					["*", "Admin", "admin"].includes(perm.moduleName)) ||
				(perm.level === level && perm.moduleName === moduleName)
			);
		});
		return exist === "T" ? perms.length > 0 : perms.length === 0;
	},

	setDeviceVersionInfo: (versionInfo = {}) => {
		const version = versionInfo?.apps || versionInfo?.software || "0.0.0";

		const regexSoftwareVersion = /(\d+\.){2}\d+/i;

		auth.appVersion = version.match(regexSoftwareVersion)
			? version.match(regexSoftwareVersion)[0]
			: "0.0.0";
	},
	isAppVersionGreater: (_version = "0.0.0") => {
		return compareVersion(auth.appVersion, _version) >= 0;
	}
};

export const useWsPermission = () => ({
	$wsSetPermissions: auth.setPermissions,
	$wsHasPermissionTo: auth.hasPermissionTo,
	$wsSetDeviceVersion: auth.setDeviceVersionInfo
});

export default {
	install(app) {
		app.config.globalProperties.$wsSetPermissions = function (...args) {
			return auth.setPermissions(...args);
		};
		app.config.globalProperties.$wsHasPermissionTo = function (...args) {
			return auth.hasPermissionTo(...args);
		};

		app.config.globalProperties.$wsSetDeviceVersion = function (...args) {
			return auth.setDeviceVersionInfo(...args);
		};

		app.directive("wsHasPermission", wsHasPermission);
		app.directive("wsHasNoPermission", wsHasNoPermission);
		app.directive("wsHasAppsVersionGreater", wsHasAppsVersionGreater);
	}
};
