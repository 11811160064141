<template>
	<div
		style="font-size: smaller"
		class="under-navbar"
		v-if="maxDataPointsReached"
	>
		<ws-notification-message
			type="warning"
			show-icon
			v-if="maxDataPointsReached"
			:show-close-button="false"
		>
			<template #title>
				{{ $t("max-data-points-reached") }}
			</template>
			{{ maxDataPointsReachedMsg }}
		</ws-notification-message>
	</div>
</template>

<script setup>
import { EventBus } from "@/eventbus.js";
import { useDeviceStore } from "@/store/device";
import { computed, ref, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
const { t: $t } = useI18n();

const store = useDeviceStore();

const timeout = ref(null);

onMounted(() => {
	EventBus.$on("changedConfiguration", refreshDataPointsCount);
});
onBeforeUnmount(() => {
	if (timeout.value) {
		clearTimeout(timeout.value);
	}
	timeout.value = null;
});

const refreshDataPointsCount = () => {
	// debounce for 1 sec
	if (timeout.value) {
		clearTimeout(timeout.value);
	}
	timeout.value = setTimeout(() => {
		store.getDataPointsCount("draft");
	}, 1000);
};

const draftDataPointsCount = computed(() => store.draftDataPointsCount);

const maxDataPointsReached = computed(() => {
	return (
		draftDataPointsCount.value &&
		draftDataPointsCount.value?.current > draftDataPointsCount.value?.max
	);
});
const maxDataPointsReachedMsg = computed(() => {
	const args = {
		current: draftDataPointsCount.value?.current || 0,
		max: draftDataPointsCount.value?.max || 0
	};
	return draftDataPointsCount.value?.hourlyBasedComputation
		? $t("max-data-points-reached-hourly-computation-msg", args)
		: $t("max-data-points-reached-msg", args);
});
</script>
<style lang="scss" scoped>
.under-navbar {
	padding: 0.5rem 1rem;
	background-color: $color-grey-300;
}
</style>
